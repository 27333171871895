import { OTHER_REASON, PROSPECTIVE } from "./AppConstants";

export function addPropAndValue(obj, prop, val) {
  obj[prop] = val;
}

export function isFormNotGoodForSave(data, sdiSuppliers) {
  let isDisabled = true;

  if (data["sdsConsidered"] === "No" && data["reasonCode"]) {
    isDisabled = false;

    if (
      data["reasonCode"] === OTHER_REASON &&
      data["comment"].trim().length === 0
    ) {
      isDisabled = true;
    }
  } else if (data["sdsConsidered"] === "Yes") {
    if (sdiSuppliers && sdiSuppliers.size > 0) isDisabled = false;

    if (data.items)
      for (let i = 0; i < data.items.length; i++) {
        let item = data.items[i];

        if (
          item["supplierType"] !== "" &&
          item["vettedProspectingTool"] !== "Select" &&
          item["vettedReasonCode"] !== "Select" &&
          item["suppliers"] &&
          item["suppliers"] !== ""
        ) {
          isDisabled = false;
          if (
            item["vettedReasonCode"] === OTHER_REASON &&
            (!item["vettedSupplierComment"] ||
              item["vettedSupplierComment"].trim().length === 0)
          )
            isDisabled = true;
          if (
            item["supplierType"] === PROSPECTIVE &&
            (!item.sdis || item.sdis.length === 0)
          )
            isDisabled = true;
        } else isDisabled = true;
        if (isDisabled) break;
      }
  } else isDisabled = true;

  return isDisabled;
}

export function getContactUsLink(userId, comments) {
  return String.raw`${
    process.env.REACT_APP_CONTACT_US_URL
  }&USERID=${userId}&COMMENTS=${comments ? comments : ""}`;
}
