import { Accordion } from "@deere/ux.uxframe-react";

import Units from "./Units";

export default function Scope({ units, unitGroups }) {
  return (
    <>
      <div
        style={{
          overflowY: "auto",
          maxHeight: "150px",
          width: "350px",
        }}
      >
        <Units units={units} />
      </div>
      {unitGroups && (
        <Accordion className="sdf-accordion m-0">
          <Accordion.Item
            id={unitGroups.code}
            title={unitGroups.name}
          >
            <Units units={unitGroups.units} className={"pl-0"} />
          </Accordion.Item>
        </Accordion>
      )}
    </>
  );
}
