import React from "react";

import { Controller } from "react-hook-form";
import { Form } from "@deere/ux.uxframe-react";

export default function SupplierDropDownSearchManual(props) {
  const { name, defaultValue } = props;
  const { control } = props.commonProps;

  return (
    <Controller
      as={<Form.Control as="textarea" maxLength="50" rows={1} />}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
}
