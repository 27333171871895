import { useEffect } from "react";
import React from "react";
import { fetchJsonWithAuth } from "../shared/fetch-json.util";

export default function GetSDIMasterList(props) {
  const {
    tokenAvailable,
    setStatusErrorMsg,
    incrementAPIInProgress,
    decrementAPIInProgress,
  } = props.commonProps;
  const { setSdiMasterList } = props;

  const fillSDIMasterList = () => {
    let api = process.env.REACT_APP_SDI_CERT_API_URL + '/params/categories/05';

    incrementAPIInProgress();
    fetchJsonWithAuth(api)
      .then((response) => {
        const sdiList = response.json.types.map((sdiObj) => sdiObj.shortName);
        let map = new Map();

        sdiList.forEach((sdi) => map.set(sdi, sdi));

        setSdiMasterList(map);
      })
      .catch((error) =>
        setStatusErrorMsg(
          "Couldn't retrieve SDI Master List. Please try again.",
          error
        )
      )
      .finally(() => decrementAPIInProgress());
  };

  useEffect(() => {
    if (tokenAvailable) fillSDIMasterList();
  }, [tokenAvailable]);

  return <></>;
}
