import { useState, useEffect } from "react";

import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Form,
  Col,
  Row,
  Card,
  ToggleButtonGroup,
  ToggleButton,
  Button,
} from "@deere/ux.uxframe-react";
import { callSdfAPI, getMGCDescription } from "../shared/SDFHelper";
import { useAPIProgress } from "../shared/APIProgress";
import { useStatusMessages } from "../shared/StatusMessages";
import { useUserInfo } from "../shared/UserInfo";
import NoAccessEmployee from "./NoAccessEmployee";
import GetProspectingAndReasonCodes from "./GetProspectingAndReasonCodes";
import GetSDIMasterList from "./GetSDIMasterList";
import GetUnits from "./GetUnits";
import SDSConsidered from "./SDSConsidered";
import UnitDropDownSearch from "./UnitDropDownSearch";
import { jdsnReactSelect } from "../shared/jdsnReactSelect";
import { jdsnReactSelectError } from "../shared/jdsnReactSelectError";
import VettedSupplierViewOnly from "./VettedSupplierViewOnly";

import {
  FORM_TYPE_IN_STRATEGY,
  FORM_TYPE_NOT_IN_STRATEGY, SMALL_BUSINESS_FORM,
} from "../shared/AppConstants";

export default function NonStrategySDForm() {
  let dateFormat = require("dateformat");
  let formatString = "dd-mmm-yyyy";
  const info = useUserInfo();
  const tokenAvailable = info && info.token;
  const loggedInUser = info && info.userId;
  const { sdfId } = useParams();

  const [sdfNumber] = useState(sdfId ? sdfId : 0);
  const [prospectingTool, setProspectingTool] = useState(null);
  const [reasonCodes, setReasonCodes] = useState(null);
  const [sdiMasterList, setSdiMasterList] = useState(null);
  const [unitMap, setUnitMap] = useState(null);

  const [sdfUser, setSdfUser] = useState(null);
  const [sdfInfo, setSdfInfo] = useState(null);
  const [suppliersMap, setSuppliersMap] = useState(null);

  const [formType] = useState(FORM_TYPE_NOT_IN_STRATEGY);

  const { incrementAPIInProgress, decrementAPIInProgress } = useAPIProgress();
  const history = useHistory();
  const hasAccess = info && info.hasNonStrategySDFAccess;

  const {
    register,
    unregister,
    getValues,
    reset,
    control,
    setError,
    errors,
    setValue,
    clearErrors,
    watch,
  } = useForm({
    mode: "all",
  });

  const { setStatusErrorMsg } = useStatusMessages();

  const commonProps = {
    tokenAvailable: tokenAvailable,
    setStatusErrorMsg: setStatusErrorMsg,
    register: register,
    unregister: unregister,
    getValues: getValues,
    incrementAPIInProgress: incrementAPIInProgress,
    decrementAPIInProgress: decrementAPIInProgress,
    control: control,
    setValue: setValue,
    errors: errors,
    reset: reset,
    clearErrors: clearErrors,
    watch: watch,
    setError: setError,
  };

  function cancelStrategy() {
    windowClose();
  }
  function windowClose() {
    if (window.opener) window.close();
    else history.push("/");
  }

  useEffect(() =>{
    if(tokenAvailable && sdfInfo?.nonStrategyFormPoClassification?.mgc){
      getMGCDescription(sdfInfo?.nonStrategyFormPoClassification?.mgc).then((response) =>
      {
        const updatedSdfInfo = {...sdfInfo}
        updatedSdfInfo.nonStrategyFormPoClassification.mgcDescription = response.get(sdfInfo.nonStrategyFormPoClassification.mgc)
        setSdfInfo(updatedSdfInfo)
      })
    }

  },[tokenAvailable, sdfInfo?.nonStrategyFormPoClassification?.mgc])

  useEffect(() => {
    if (tokenAvailable && sdfNumber > 0) {
      callSdfAPI(
          sdfNumber,
          setSdfUser,
          setSdfInfo,
          setSuppliersMap,
          incrementAPIInProgress,
          decrementAPIInProgress,
          setStatusErrorMsg
      );
    }
  }, [sdfNumber, tokenAvailable]);

  return !info ? (
      <></>
  ) : !hasAccess ? (
      <NoAccessEmployee userId={loggedInUser} />
  ) : (
      <main id="content" className="container-fluid pb-5 bg-gray">
        <Form>
          <div className="container-fluid pb-2 bg-gray position-sticky fixed-top">
            <Row className="mt-3">
              <Col>
                <h2 className="mt-2 float-left">
                  {SMALL_BUSINESS_FORM} # {sdfId}
                </h2>
              </Col>
              <Col>
                <div className="mt-2 float-right">
                  <Button variant="secondary" onClick={cancelStrategy}>
                    Cancel
                  </Button>
                  <Button
                      variant="primary"
                      onClick={() => history.push("/editSDForm/" + sdfId)}
                  >
                    Edit
                  </Button>
                </div>
              </Col>
            </Row>
            {sdfNumber > 0 && (
                <h6>
                  Last updated on{" "}
                  {sdfInfo && dateFormat(new Date(sdfInfo.updatedOn), formatString)}{" "}
                  by {sdfUser ? sdfUser : sdfInfo && sdfInfo.updatedById}
                </h6>
            )}
          </div>
          <GetProspectingAndReasonCodes
              commonProps={commonProps}
              setReasonCodes={setReasonCodes}
              setProspectingTool={setProspectingTool}
          />
          <GetSDIMasterList
              commonProps={commonProps}
              setSdiMasterList={setSdiMasterList}
          />
          <GetUnits commonProps={commonProps} setUnitMap={setUnitMap} />

          <Row className="mt-3">
            <Col>
              <Card>
                <Card.Body>
                  <Form.Row>
                    <Form.Group as={Col} xs={15} md={20}>
                      <ToggleButtonGroup
                          role="toolbar"
                          includeLabel
                          className="mt-3"
                          label="Is this for a Strategy or a single purchase (not in
                      Strategy)?"
                          name="radioButtonGroup"
                          type="radio"
                          value={formType === FORM_TYPE_IN_STRATEGY ? 1 : 2}
                          ariaLabel="Is this for a Strategy or a single purchase (not in
                        Strategy)?"
                      >
                        <ToggleButton
                            value={1}
                            disabled={formType !== FORM_TYPE_IN_STRATEGY}
                        >
                          In Strategy
                        </ToggleButton>
                        <ToggleButton
                            value={2}
                            disabled={formType !== FORM_TYPE_NOT_IN_STRATEGY}
                        >
                          Not In Strategy
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col}  md={20}>
                      <Form.Label className="uxf-label">
                        Purchase Order or Scheduling Agreement Number{" "}
                        <span
                            aria-label="required field"
                            className="uxf-is-required"
                        >
                        *
                      </span>
                      </Form.Label>
                      <Form.Control
                          ref={(e) => {
                            register(e, {
                              required: "This is a required field!",
                            });
                          }}
                          name="POorSANumber"
                          disabled={true}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md={20}>
                      <Form.Label className="uxf-label">
                        Unit{" "}
                        <span
                            aria-label="required field"
                            className="uxf-is-required"
                        >
                        *
                      </span>
                      </Form.Label>
                      {unitMap && (
                          <UnitDropDownSearch
                              key={sdfInfo && sdfInfo.nonStrategyFormUnitCode}
                              readOnly={true}
                              name="unit"
                              setStatusErrorMsg={setStatusErrorMsg}
                              styles={
                                errors.unit ? jdsnReactSelectError : jdsnReactSelect
                              }
                              defaultValue={
                                  sdfInfo && sdfInfo.nonStrategyFormUnitCode
                              }
                              rules={{ required: true }}
                              commonProps={commonProps}
                              unitMap={unitMap}
                              disabled={true}
                          />
                      )}
                    </Form.Group>
                  </Form.Row>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body>
                  <Form.Row>
                    <Form.Group as={Col}  md={20}>
                      <Form.Label className="uxf-label">
                        MGC <div style={{fontWeight: "normal"}}>{(sdfInfo?.nonStrategyFormPoClassification?.mgc ?? "N/A") +  (sdfInfo?.nonStrategyFormPoClassification?.mgcDescription ? " - " + sdfInfo?.nonStrategyFormPoClassification?.mgcDescription:"" )}</div>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} md={20}>
                      <Form.Label className="uxf-label">
                        Category Level 1 <div style={{fontWeight: "normal"}}>{sdfInfo?.nonStrategyFormPoClassification?.categoryLevel1 ?? "N/A" }</div>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md={20}>
                      <Form.Label className="uxf-label">
                        Category Level 2 <div style={{fontWeight: "normal"}}>{sdfInfo?.nonStrategyFormPoClassification?.categoryLevel2 ?? "N/A"}</div>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md={20}>
                      <Form.Label className="uxf-label">
                        Category Level 3 <div style={{fontWeight: "normal"}}>{sdfInfo?.nonStrategyFormPoClassification?.categoryLevel3 ?? "N/A"}</div>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md={15}>
                      <Form.Label className="uxf-label">
                        Category Level 4 <div style={{fontWeight: "normal"}}>{sdfInfo?.nonStrategyFormPoClassification?.categoryLevel4 ?? "N/A"}</div>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md="24">
              {sdfInfo && (
                  <SDSConsidered
                      commonProps={commonProps}
                      reasonCodes={reasonCodes}
                      suppliersMap={suppliersMap}
                      prospectingTool={prospectingTool}
                      sdfInfo={sdfInfo}
                      initialSdsConsidered={
                        sdfInfo && sdfInfo.sdsConsidered ? "Yes" : "No"
                      }
                      sdiMasterList={sdiMasterList}
                      readOnly={true}
                  />
              )}
            </Col>
          </Row>
          {sdfInfo && sdfInfo.vettedSuppliers.length > 0 && (
              <>
                <h4>Vetted Suppliers Who Were Not Included</h4>
                <Row>
                  <Col md="24">
                    {sdfInfo.vettedSuppliers.map((item) => (
                        <VettedSupplierViewOnly
                            key={item.supplierId}
                            vettedSupplier={item}
                            suppliersMap={suppliersMap}
                            prospectingTool={prospectingTool}
                            reasonCodes={reasonCodes}
                        />
                    ))}
                  </Col>
                </Row>
              </>
          )}
        </Form>
      </main>
  );
}
