import { useState, useEffect, useRef } from "react";
import { fetchJsonWithAuth } from "../shared/fetch-json.util";
import { useUserInfo } from "../shared/UserInfo";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "@deere/ux.uxframe-react";
import NoAccessEmployee from "./NoAccessEmployee";
import { useAPIProgress } from "../shared/APIProgress";
import { useStatusMessages } from "../shared/StatusMessages";
import { addPropAndValue } from "./../shared/UtilFunctions";
import SDForm from "./SDForm";
import { List, ListItem, Form, Card } from "@deere/ux.uxframe-react";
import GetUnits from "./GetUnits";
import MessageBanner from "../shared/MessageBanner";
import NonStrategyUpdaterFilter from "../filters/NonStrategyUpdaterFilter";
import SDFormWithSortandPagination from "./SDFormWithSortandPagination";
import UnitsFilter from "../filters/UnitsFilter";
import { getFormattedUserNameWithId } from "../shared/UserLogin";

export default function SearchSDForm() {
  const info = useUserInfo();
  const tokenAvailable = info && info.token;
  const hasAccess = info && info.hasNonStrategySDFAccess;
  const loggedInUser = info && info.userId;
  const [nonStrategySDForms, setNonStrategySDForms] = useState(null);
  const [listAfterFilter, setListAfterFilter] = useState(null);

  const searchRef = useRef(null);
  const [formList, setFormList] = useState(null);
  const [unitMap, setUnitMap] = useState(null);
  const [nonStrategyOwnerMap, setNonStrategyOwnerMap] = useState(null);
  const [selectedNonStrategyUpdaterIds, setSelectedNonStrategyUpdaterIds] =
    useState(null);
  const [selectedUnits, setSelectedUnits] = useState(null);

  const [searchCriteriaForAPI, setSearchCriteriaForAPI] = useState(null);

  const { apiInProgressCount, incrementAPIInProgress, decrementAPIInProgress } =
    useAPIProgress();

  const { statusMessages, clearAllMessages, setStatusErrorMsg } =
    useStatusMessages();

  const commonProps = {
    tokenAvailable: tokenAvailable,
    setStatusErrorMsg: setStatusErrorMsg,
    incrementAPIInProgress: incrementAPIInProgress,
    decrementAPIInProgress: decrementAPIInProgress,
  };

  function search(e) {
    e.preventDefault();
    callNonStrategySDFAPI(true);
    window.scrollTo(0, 0);
  }
  function getSearchCriteria(viewResults) {
    let searchObj = {};

    addPropAndValue(searchObj, "formTypeId", "201");
    if (
      viewResults &&
      selectedNonStrategyUpdaterIds &&
      selectedNonStrategyUpdaterIds.length > 0
    )
      addPropAndValue(
        searchObj,
        "formUpdaters",
        selectedNonStrategyUpdaterIds.split(",")
      );

    if (viewResults && selectedUnits && selectedUnits.length > 0)
      addPropAndValue(searchObj, "units", selectedUnits.split(","));

    return searchObj;
  }

  function callNonStrategySDFAPI(viewResults) {
    let searchObj;
    let criteriaFromSession = true;

    if (!viewResults) {
      searchObj = populateSearchCriteria();
      if (!searchObj) {
        searchObj = getSearchCriteria(viewResults);
        criteriaFromSession = false;
      }
    } else {
      searchObj = getSearchCriteria(viewResults);
      setSearchCriteriaForAPI(searchObj);
      storeSearchCriteria(searchObj);
    }

    let apiUrl = process.env.REACT_APP_SDF_API_URL + "/search";

    clearAllMessages();
    incrementAPIInProgress();

    fetchJsonWithAuth(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(searchObj),
    })
      .then((response) => {
        const result = response.json;
        let formList = result;

        // Filter results if logged-in-user has form(s) Initially only when there is no criteria in Session
        if (!viewResults && !criteriaFromSession) {
          let filteredFormList = result.filter(
            (item) =>
              loggedInUser.localeCompare(item.updatedById, undefined, {
                sensitivity: "accent",
              }) === 0
          );

          if (filteredFormList.length > 0) formList = filteredFormList;
        }

        setFormList(formList);
        searchRef.current.value = "";
      })
      .catch((error) =>
        setStatusErrorMsg(
          "Couldn't retrieve the Non-Strategy SDF info. Please try again.",
          error
        )
      )
      .finally((info) => decrementAPIInProgress());
  }

  useEffect(() => {
    if (tokenAvailable) {
      if (hasAccess) callNonStrategySDFAPI(false);
      setSelectedNonStrategyUpdaterIds(loggedInUser.toUpperCase());
    }
  }, [tokenAvailable]);

  function populateSearchCriteria() {
    let sessionObj = sessionStorage.getItem("searchCriteriaLabel");
    let parsedObj = sessionObj && JSON.parse(sessionObj);

    sessionObj = sessionStorage.getItem("searchCriteria");
    parsedObj = sessionObj && JSON.parse(sessionObj);
    setSearchCriteriaForAPI(parsedObj);

    return parsedObj;
  }

  function storeSearchCriteria(obj) {
    sessionStorage.setItem("searchCriteria", JSON.stringify(obj));
  }
  function filterList(e) {
    let input = e.target.value.trim().toLowerCase();

    setListAfterFilter(
      input.length > 0
        ? nonStrategySDForms.filter(
            (element) =>
              element.props.sdform.formId.toString().includes(input) || // filter formId
              element.props.sdform.nonStrategyFormPONumber.includes(input) || // filter PO
              element.props.unitName.toLowerCase().includes(input) || // filter Unit Name
              element.props.sdform.nonStrategyFormUnitCode
                .toLowerCase()
                .includes(input) || // filter Unit Code
              element.props.updatedByUserName.toLowerCase().includes(input) // filter name
          )
        : nonStrategySDForms
    );
  }
  function getUnit(unit) {
    let unitName = unitMap.get(unit);

    if (!unitName) unitName = unit;
    return unitName;
  }
  function getUserName(updatedById) {
    let userName = getFormattedUserNameWithId(
      nonStrategyOwnerMap
        ? nonStrategyOwnerMap.get(updatedById.toUpperCase())
        : null,
      updatedById
    );
    return userName;
  }
  useEffect(() => {
    if (formList) {
      let list = formList.map((item) => (
        <SDForm
          key={item.formId}
          sdform={item}
          unitName={
            unitMap
              ? getUnit(item.nonStrategyFormUnitCode)
              : item.nonStrategyFormUnitCode
          }
          updatedByUserName={
            nonStrategyOwnerMap
              ? getUserName(item.updatedById)
              : item.updatedById
          }
        />
      ));
      setNonStrategySDForms(list);
      setListAfterFilter(list);
    }
  }, [formList, unitMap, nonStrategyOwnerMap]);

  return !info ? (
    <></>
  ) : !hasAccess ? (
    <NoAccessEmployee userId={loggedInUser} />
  ) : (
    <main id="content" className="container-fluid pb-5 bg-gray">
      <div>
        <br />
        <Row>
          <Col className="col-md-17">
            <h3>Search</h3>
          </Col>
          <Col>
            <Link
              to={{
                pathname: "/createSDForm",
              }}
            >
              <Button className="float-right" style={{ marginRight: "auto" }}>
                + Create New
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
      <Row className="mt-3">
        <div className="col-md-7">
          <div className="jdsn-facet-container">
            <NonStrategyUpdaterFilter
              setSelectedNonStrategyUpdaterIds={
                setSelectedNonStrategyUpdaterIds
              }
              setUserMapFn={setNonStrategyOwnerMap}
              commonProps={commonProps}
              info={info}
              selectedValues={
                searchCriteriaForAPI
                  ? searchCriteriaForAPI.formUpdaters
                  : selectedNonStrategyUpdaterIds &&
                    selectedNonStrategyUpdaterIds.split()
              }
            />
            <UnitsFilter
              key={unitMap}
              unitMap={unitMap}
              setSelectedUnits={setSelectedUnits}
              selectedValues={
                searchCriteriaForAPI
                  ? searchCriteriaForAPI.units
                  : selectedUnits && selectedUnits.split()
              }
            />

            <Card className="fixed-bottom position-sticky view-result">
              <Row className="align-items-center h-100">
                <Col md="24" className="col w-100 text-center">
                  <Button
                    className="btn btn-primary btn-block"
                    style={{ marginRight: "auto" }}
                    onClick={search}
                  >
                    View Results
                  </Button>
                </Col>
              </Row>
            </Card>
          </div>
        </div>
        <div className="col-md-17">
          <Row>
            <Col>
              <Form.Control
                ref={searchRef}
                className="search-input"
                type="text"
                placeholder="Filter these results"
                onChange={filterList}
                disabled={nonStrategySDForms && nonStrategySDForms.length === 0}
              />
            </Col>
          </Row>
          <br />
          <MessageBanner
            statusMessages={statusMessages}
            apiInProgressCount={apiInProgressCount}
          />
          <Form role="search">
            {listAfterFilter &&
              (listAfterFilter.length === 0 ? (
                <List className="list-group">
                  <ListItem className="list-group-item">
                    No results found. Please try a new search term or expanding
                    your filter criteria.
                  </ListItem>
                </List>
              ) : (
                <SDFormWithSortandPagination list={listAfterFilter} />
              ))}
          </Form>
        </div>
      </Row>
      <GetUnits commonProps={commonProps} setUnitMap={setUnitMap} />
    </main>
  );
}
