import { useState } from "react";
import { getFormattedUserNameWithId } from "../shared/UserLogin";
import GetMasterCategoriesAndScopes from "./GetMasterCategoriesAndScopes";
import CategoriesReadOnly from "../shared/CategoriesReadOnly";
import RPCOrUnitsViewOnly from "../shared/RPCOrUnitsViewOnly";
import { Form, Col, Card } from "@deere/ux.uxframe-react";
import StrategyOrSinglePO from "./StrategyOrSinglePO";
import StrategyType from "./StrategyType";
import { SCOPE_LEVEL_RPCORUNIT } from "../shared/AppConstants";

export default function SMIndirectStrategy(props) {
  const { formType, strategyInfo, applicationName, commonProps } = props;
  const [masterCategoryJson, setMasterCategoryJson] = useState(null);
  const [masterScopeJson, setMasterScopeJson] = useState(null);
  const [masterCategoryMap, setMasterCategoryMap] = useState(null);
  const [masterScopeMap, setMasterScopeMap] = useState(null);

  const childProps = {
    tokenAvailable: commonProps.tokenAvailable,
    incrementAPIInProgress: commonProps.incrementAPIInProgress,
    decrementAPIInProgress: commonProps.decrementAPIInProgress,
    setStatusErrorMsg: commonProps.setStatusErrorMsg,
    setStatusSuccessMsg: commonProps.setStatusSuccessMsg,
    setMasterScopeJson: setMasterScopeJson,
    setMasterCategoryJson: setMasterCategoryJson,
    setMasterScopeMap: setMasterScopeMap,
    setMasterCategoryMap: setMasterCategoryMap,
  };

  return (
    <>
      <GetMasterCategoriesAndScopes commonProps={childProps} />

      <Card>
        <Card.Body>
          <Form.Row>
            <StrategyOrSinglePO formType={formType} />
            <StrategyType
              applicationName={applicationName}
              strategyInfo={strategyInfo}
            />
            <Form.Group as={Col} xs={9} md={6}>
              <Form.Label className="uxf-label">Strategy Owner</Form.Label>
              <h5>
                {getFormattedUserNameWithId(
                  strategyInfo.userNameInfo,
                  strategyInfo.userId
                )}
              </h5>
            </Form.Group>
          </Form.Row>
          <br />
          <Form.Row>
            {strategyInfo && strategyInfo.categories.length > 0 && (
              <Form.Group as={Col} xs={20} md={10}>
                <CategoriesReadOnly
                  categoriesFromParentRead={
                    strategyInfo && strategyInfo.categories
                  }
                  masterCategory={masterCategoryJson}
                  commonProps={commonProps}
                />
              </Form.Group>
            )}
            <Form.Group as={Col}>
              <Form.Label className="uxf-label">Scope </Form.Label>

              {strategyInfo && strategyInfo.scope && (
                <div
                  style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    maxHeight: "400px",
                  }}
                >
                  <b>{strategyInfo.scope.level}</b>
                  {strategyInfo.scope.level === SCOPE_LEVEL_RPCORUNIT && (
                    <RPCOrUnitsViewOnly
                      rpcOrUnitsFromParentRead={
                        strategyInfo && strategyInfo.scope
                      }
                      masterRpcOrUnits={masterScopeJson}
                      commonProps={commonProps}
                    />
                  )}
                </div>
              )}
            </Form.Group>
          </Form.Row>
        </Card.Body>
      </Card>
    </>
  );
}
