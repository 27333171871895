import _ from "lodash";

let authorizationToken;
let loggedInUserId;
let logoutFunction;
const HTTP_UNAUTHORIZED = 401;
const HTTP_FORBIDDEN = 403;

/**
 * Make the configuration required for requests that send the bearer token authorization.
 * @param {Object} [init] Add the configuration to this object.
 * @returns {Object} The fetch configuration object with authorization headers populated.
 */
function makeBearerConfig(init = {}) {
  init.credentials = "include";
  init.headers = init.headers || {};
  init.headers.Authorization = `Bearer ${authorizationToken}`;

  return init;
}

/**
 * Only used by pa-user-session.class
 * @param {String} token The bearer authorization token.
 * @returns {undefined}
 */
export function setAuthorizationToken(token) {
  authorizationToken = token;
}

export function setLoggedInUserId(userId) {
  loggedInUserId = userId;
}

/**
 * Only used by pa-user-session.class
 * @param {Function} logout The function to force a logout.
 * @returns {undefined}
 */
export function setLogoutFunction(logout) {
  logoutFunction = logout;
}

/**
 * Only used by pa-user-session.class and the services.
 * @returns {String} The bearer authorization token.
 */
export function getAuthorizationToken() {
  return authorizationToken;
}

export function getLoggedInUserId() {
  return loggedInUserId;
}

/**
 * Calls fetch and converts the response to JSON.
 * @param {*} input See fetch API for details.
 * @param  {*} init See fetch API for details.
 * @returns {Promise} resolved with the JSON for a 200, rejected otherwise
 */
export function fetchJson(input, init) {
  return fetch(input, init).then(status).then(parseFetchResponse);
}

/**
 * Calls fetch with the bearer token authorization.
 * Logs the user out if the server sends a 401.
 * @param {*} input See fetch API for details.
 * @param  {*} init See fetch API for details.
 * @returns {Promise} See fetch API for details.  If a 401 is sent the promise will be rejected.
 */
export function fetchWithAuth(input, init) {
  const initWithBearer = makeBearerConfig(init);

  return fetch(input, initWithBearer).then(status);
}

function status(response) {
  if (
    response.status === HTTP_UNAUTHORIZED ||
    response.status === HTTP_FORBIDDEN
  ) {
    if (logoutFunction) logoutFunction();

    return Promise.reject();
  } else if (response.status >= 200 && response.status <= 404)
    return Promise.resolve(response);
  else return Promise.reject(response);
}

const parseFetchResponse = (response) =>
  response.json().then((text) => ({
    json: text,
    meta: response,
  }));

/**
 * Calls fetchWithAuth and converts the response to JSON.
 * @param {*} input See fetch API for details.
 * @param  {*} init See fetch API for details.
 * @returns {Promise} resolved with the JSON for a 200, rejected otherwise
 */
export function fetchJsonWithAuth(input, init) {
  return fetchWithAuth(input, init).then(parseFetchResponse);
}

export function undefinedIfEmpty(value) {
  return _.isEmpty(value) ? undefined : value;
}
