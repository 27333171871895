import { AccordionIcon } from "./accordion-icon";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import styles from "./base-accordion.module.scss";

export const AccordionHeader = (props) => {
  const {
    AccordionIconProps,
    className,
    children,
    iconClosed,
    iconOpen,
    iconCollapsed,
    isOpen,
    cbSelected,
    ...extraProps
  } = props;

  return (
    <div
      className={classNames(styles.accordionHeader, className)}
      data-testid="accordion-header"
      onClick={props.onClick}
      {...extraProps}
    >
      <AccordionIcon
        iconClosed={iconClosed}
        iconOpen={iconOpen}
        iconCollapsed={iconCollapsed}
        isOpen={isOpen}
        cbSelected={cbSelected}
        {...AccordionIconProps}
      />
      {children}
    </div>
  );
};

AccordionHeader.propTypes = {
  AccordionIconProps: PropTypes.object,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  iconClosed: PropTypes.node.isRequired,
  iconOpen: PropTypes.node.isRequired,
  iconCollapsed: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  cbSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

AccordionHeader.defaultProps = {
  AccordionIconProps: {},
  className: "",
};
