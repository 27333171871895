import { useEffect } from "react";
import React from "react";
import { fetchJsonWithAuth } from "../shared/fetch-json.util";

export default function GetMasterCategoriesAndScopes(props) {
  const {
    tokenAvailable,
    setStatusErrorMsg,
    incrementAPIInProgress,
    decrementAPIInProgress,
    setMasterScopeJson,
    setMasterCategoryJson,
    setMasterScopeMap,
    setMasterCategoryMap,
  } = props.commonProps;

  const fillScopeData = () => {
    let api = process.env.REACT_APP_RPCSUNITS_API_URL;

    incrementAPIInProgress();
    fetchJsonWithAuth(api)
      .then((response) => {
        setMasterScopeJson(response.json);

        let scopeMap = new Map();

        response.json.forEach((rpc) => {
          scopeMap.set(rpc.rpcCode, rpc.rpcName);
          rpc.units.forEach((unit) => {
            scopeMap.set(unit.code, unit.name + " - " + unit.code);
          });
        });
        setMasterScopeMap(scopeMap);
      })
      .catch((error) =>
        setStatusErrorMsg(
          "Couldn't retrieve Master Scope info. Please try again.",
          error
        )
      )
      .finally((info) => decrementAPIInProgress());
  };

  function loadMasterCategoriesMap(categoriesData) {
    if (categoriesData) {
      let portfolioMap = new Map();

      categoriesData.forEach((portfolio) => {
        let scMap = new Map();

        portfolioMap.set(portfolio.code, portfolio);
        portfolio.map = scMap;

        portfolio.supplyCouncils.forEach((supplyCouncil) => {
          let l1Map = new Map();

          scMap.set(supplyCouncil.code, supplyCouncil);
          supplyCouncil.map = l1Map;

          supplyCouncil.categoryLevel1s.forEach((categoryLevel1) => {
            let l2Map = new Map();

            l1Map.set(categoryLevel1.code, categoryLevel1);
            categoryLevel1.map = l2Map;

            categoryLevel1.categoryLevel2s.forEach((categoryLevel2) => {
              let l3Map = new Map();

              l2Map.set(categoryLevel2.code, categoryLevel2);
              categoryLevel2.map = l3Map;

              categoryLevel2.categoryLevel3s.forEach((categoryLevel3) => {
                let l4Map = new Map();

                l3Map.set(categoryLevel3.code, categoryLevel3);
                categoryLevel3.map = l4Map;
                categoryLevel3.categoryLevel4s.forEach((categoryLevel4) => {
                  l4Map.set(categoryLevel4.code, categoryLevel4);
                });
              });
            });
          });
        });
      });

      setMasterCategoryMap(portfolioMap);
    }
  }

  function fillCategoriesData() {
    let api = process.env.REACT_APP_PORTFOLIOS_API_URL;

    incrementAPIInProgress();
    fetchJsonWithAuth(api)
      .then((response) => {
        const result = response.json;

        setMasterCategoryJson(result);
        loadMasterCategoriesMap(result);
      })
      .catch((error) =>
        setStatusErrorMsg(
          "Couldn't retrieve the Master Category info. Please try again.",
          error
        )
      )
      .finally((info) => decrementAPIInProgress());
  }

  useEffect(() => {
    if (tokenAvailable) {
      fillCategoriesData();
      fillScopeData();
    }
  }, [tokenAvailable]);

  return <></>;
}
