import { CSSTransitionGroup } from "react-transition-group";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import styles from "./accordion-body.module.scss";

export const AccordionBody = (props) => {
  const { children, className, isOpen, ...extraProps } = props;

  return (
    <CSSTransitionGroup
      component="div"
      transitionEnterTimeout={Number(styles.animationDelay)}
      transitionLeaveTimeout={Number(styles.animationDelay)}
      transitionName={{
        enter: styles.enter,
        enterActive: styles.enterActive,
        leave: styles.leave,
        leaveActive: styles.leaveActive,
      }}
    >
      {isOpen && (
        <div
          className={classNames(styles.accordionBody, className)}
          data-testid="accordion-body"
          {...extraProps}
        >
          {children}
        </div>
      )}
    </CSSTransitionGroup>
  );
};

AccordionBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
};

AccordionBody.defaultProps = {
  className: "",
  isOpen: false,
};
