import { List, ListItem } from "@deere/ux.uxframe-react";

export default function Units({ units, className }) {
  return (
    <>
      {units &&
        units.length > 0 &&
        units.sort((a, b) => a.name.localeCompare(b.name)) && (
          <List variant="uxf-ul" className={className}>
            {units.map((elem) => (
              <ListItem key={elem.code}>
                {elem.name} - {elem.code}
              </ListItem>
            ))}
          </List>
        )}
    </>
  );
}
