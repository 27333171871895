import React, { useReducer } from "react";

export function useAPIProgress() {
  const apiProgressReducer = (state, action) => {
    switch (action.type) {
      case "increment":
        return { count: state.count + 1 };
      case "decrement":
        return { count: state.count - 1 };
      default:
        throw new Error();
    }
  };

  const [apiInProgressCount, dispatch] = useReducer(apiProgressReducer, {
    count: 0,
  });
  const incrementAPIInProgress = React.useCallback(
    () => dispatch({ type: "increment" }),
    []
  );
  const decrementAPIInProgress = React.useCallback(
    () => dispatch({ type: "decrement" }),
    []
  );

  return { apiInProgressCount, incrementAPIInProgress, decrementAPIInProgress };
}
