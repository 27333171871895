import React, { Component } from "react";
import DebounceInput from "react-debounce-input";
import "../styles/styles.scss";
import { baseDefaultProps, baseInputFilterPropTypes } from "./prop-types";

class BaseInputFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
    };

    this.handleOnFocus = (...args) => {
      this.setState({
        isActive: true,
      });

      this.props.onFocus(...args);
    };

    this.handleOnBlur = (...args) => {
      this.setState({
        isActive: false,
      });

      this.props.onBlur(...args);
    };

    this.handleEnterKey = (event) => {
      if (event.nativeEvent.key === "Enter") {
        this.props.onSearch(event.target.value);
      }
    };
  }

  render() {
    return (
      <div
        className={`${this.props.className} ${
          this.state.isActive ? "base-input-filter-focus" : ""
        } ${this.props.disabled ? "disabled" : ""}`}
        data-testid="base-input-filter"
      >
        <DebounceInput
          autoFocus={this.props.autoFocus}
          className="filter-input"
          debounceTimeout={this.props.debounceTimeout}
          disabled={this.props.disabled}
          inputRef={this.props.inputRef}
          maxLength={this.props.maxLength}
          onBlur={this.handleOnBlur}
          onChange={this.props.onChange}
          onFocus={this.handleOnFocus}
          onKeyUp={this.handleEnterKey}
          placeholder={this.props.placeholder}
          type="search"
          value={this.props.value}
        />
        {this.props.controls}
      </div>
    );
  }
}

BaseInputFilter.propTypes = baseInputFilterPropTypes;
BaseInputFilter.defaultProps = baseDefaultProps;

export default BaseInputFilter;
