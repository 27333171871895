import { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function usePrompt(
	when,
	message = "Changes you made may not be saved."
) {
	const history = useHistory();
	const unblock = useRef(null);

	useEffect(() => {
		unblock.current = when ? history.block(message) : null;
		return () => {
			if (unblock.current) unblock.current();
		};
	}, [when, history, message]);
}
