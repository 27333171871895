import React from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Button } from "@deere/ux.uxframe-react";
export default function App404() {
  const history = useHistory();
  function goBack() {
    history.push("/");
  }
  return (
    <main id="content" className="container-fluid bg-gray pt-4 pb-5">
      <Row className="align-items-center h-100">
        <Col md="24" className="col w-100 text-center">
          <h1 className="display-6">404 Page Not Found</h1>
          <svg
            focusable="false"
            aria-hidden="true"
            fill="#666666"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style={{ width: "200px", height: "200px" }}
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path>
          </svg>
          <p className="lead">This page does not exist.</p>
          <div className="pt-5 pb-5">
            <Button variant="primary" onClick={goBack}>
              Back Home
            </Button>
          </div>
        </Col>
      </Row>
    </main>
  );
}
