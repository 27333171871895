import React from "react";
import { Col, Row, Form } from "@deere/ux.uxframe-react";
import { STRATEGY_TYPE_DIRECT } from "./AppConstants";
import { Accordion } from "./base-accordion/accordion";
import { ReactComponent as Indeterminate } from "../Icons/CheckboxIndeterminate.svg";

export default function CategoriesReadOnly(props) {
  const categoriesFromParentRead = props.categoriesFromParentRead;
  const categoryData = props.masterCategory;

  return (
    <Form.Row>
      <Form.Group as={Col}>
        <Form.Label className="uxf-label">Categories </Form.Label>
        <div
          style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "400px" }}
        >
          {categoryData &&
            categoryData.map((portfolio, index) => {
              let portMatch = categoriesFromParentRead.filter(
                (record) => record.portfolio === portfolio.code
              );
              return (
                portfolio.name !== STRATEGY_TYPE_DIRECT &&
                portMatch.length > 0 && (
                  <>
                    <Row className="col-md-60">
                      <Col className="col-md-30">
                        <p className="mb-2">
                          <b className="fontSize">Portfolio</b>
                          <span className="fontStyle">{portfolio.name}</span>
                        </p>
                      </Col>
                    </Row>
                    {portfolio.supplyCouncils.map((supplyCouncil, index) => {
                      let supplyCouncilMatch = categoriesFromParentRead.filter(
                        (record) => record.supplyCouncil === supplyCouncil.code
                      );

                      return (
                        supplyCouncilMatch.length > 0 && (
                          <>
                            <Row>
                              <Col className="col-md-30">
                                <p className="mb-2">
                                  {" "}
                                  <b className="fontSize">Supply Council</b>
                                  <span className="fontStyle">
                                    {supplyCouncil.name}
                                  </span>
                                </p>
                              </Col>
                            </Row>

                            {supplyCouncil.categoryLevel1s.map(
                              (categoryLevel1, index) => {
                                let categoryLevel1Match = categoriesFromParentRead.filter(
                                  (record) =>
                                    record.categoryLevel1 ===
                                    categoryLevel1.code
                                );
                                return (
                                  categoryLevel1Match.length > 0 && (
                                    <>
                                      <Row>
                                        <Col className="col-md-30">
                                          <p className="mb-2">
                                            <b className="fontSize">
                                              Category Level 1
                                            </b>
                                            <span className="fontStyle">
                                              {categoryLevel1.name}
                                            </span>
                                          </p>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col className="col-md-30">
                                          <p className="mb-2">
                                            <b className="fontSize">
                                              Category Level 2
                                            </b>
                                          </p>
                                          {categoryLevel1.categoryLevel2s.map(
                                            (categoryLevel2, index) => {
                                              let hasCat3 = false;

                                              let categoryLevel2Match = categoriesFromParentRead.filter(
                                                (record) =>
                                                  record.categoryLevel2 ===
                                                  categoryLevel2.code
                                              );
                                              return (
                                                categoryLevel2Match.length >
                                                  0 && (
                                                  <>
                                                    {categoryLevel2.categoryLevel3s.forEach(
                                                      (
                                                        categoryLevel3,
                                                        index
                                                      ) => {
                                                        let categoryLevel3Match = categoriesFromParentRead.filter(
                                                          (record) =>
                                                            record.categoryLevel3 ===
                                                            categoryLevel3.code
                                                        );
                                                        //checks for every catLevel3 match, if length is>0 it reset hasCat3 to true. hasCat3 helps to determine status for CatLevel2.
                                                        categoryLevel3Match.length >
                                                          0 && (hasCat3 = true);
                                                      }
                                                    )}
                                                    <Row>
                                                      <Col className="col-md-30">
                                                        <Accordion
                                                          color={"WHITE"}
                                                          data-testid={`standard-accordion-${"SMALL"}-${"WHITE"}`}
                                                          header={
                                                            hasCat3 ? (
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "14px",
                                                                }}
                                                              >
                                                                <Indeterminate />{" "}
                                                                {
                                                                  categoryLevel2.name
                                                                }
                                                              </span>
                                                            ) : (
                                                              <span className="tickMark">
                                                                {
                                                                  categoryLevel2.name
                                                                }
                                                              </span>
                                                            )
                                                          }
                                                          size={"SMALL"}
                                                          isOpen={
                                                            hasCat3
                                                              ? true
                                                              : false
                                                          }
                                                        >
                                                          <div className="containerViewOnly">
                                                            <p className="mb-2">
                                                              <b className="fontSize">
                                                                Category Level 3
                                                              </b>
                                                            </p>
                                                            {categoryLevel2.categoryLevel3s.map(
                                                              (
                                                                categoryLevel3,
                                                                index
                                                              ) => {
                                                                let hasCat4 = false;

                                                                let categoryLevel3Match = categoriesFromParentRead.filter(
                                                                  (record) =>
                                                                    record.categoryLevel3 ===
                                                                    categoryLevel3.code
                                                                );
                                                                return hasCat3 ? (
                                                                  categoryLevel3Match.length >
                                                                    0 && (
                                                                    <>
                                                                      {categoryLevel3.categoryLevel4s.forEach(
                                                                        (
                                                                          categoryLevel4,
                                                                          index
                                                                        ) => {
                                                                          let categoryLevel4Match = categoriesFromParentRead.filter(
                                                                            (
                                                                              record
                                                                            ) =>
                                                                              record.categoryLevel4 ===
                                                                              categoryLevel4.code
                                                                          );
                                                                          //checks for every catLevel4 match, if length is>0 it reset hasCat4 to true. hasCat4 helps to determine status for CatLevel3.
                                                                          categoryLevel4Match.length >
                                                                            0 &&
                                                                            (hasCat4 = true);
                                                                        }
                                                                      )}

                                                                      <Row>
                                                                        <Col className="col-md-30">
                                                                          <Accordion
                                                                            color={
                                                                              "WHITE"
                                                                            }
                                                                            data-testid={`standard-accordion-${"SMALL"}-${"WHITE"}`}
                                                                            header={
                                                                              hasCat4 ? (
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "14px",
                                                                                  }}
                                                                                >
                                                                                  <Indeterminate />{" "}
                                                                                  {
                                                                                    categoryLevel3.name
                                                                                  }
                                                                                </span>
                                                                              ) : (
                                                                                <span className="tickMark">
                                                                                  {
                                                                                    categoryLevel3.name
                                                                                  }
                                                                                </span>
                                                                              )
                                                                            }
                                                                            size={
                                                                              "SMALL"
                                                                            }
                                                                            isOpen={
                                                                              hasCat4
                                                                                ? true
                                                                                : false
                                                                            }
                                                                          >
                                                                            <div className="containerViewOnly">
                                                                              <p className="mb-2">
                                                                                <b className="fontSize">
                                                                                  Category
                                                                                  Level
                                                                                  4
                                                                                </b>
                                                                              </p>
                                                                              {categoryLevel3.categoryLevel4s.map(
                                                                                (
                                                                                  categoryLevel4,
                                                                                  index
                                                                                ) => {
                                                                                  let categoryLevel4Match = categoriesFromParentRead.filter(
                                                                                    (
                                                                                      record
                                                                                    ) =>
                                                                                      record.categoryLevel4 ===
                                                                                      categoryLevel4.code
                                                                                  );
                                                                                  return (
                                                                                    <Row>
                                                                                      <Col className="col-md-30">
                                                                                        <span
                                                                                          className={
                                                                                            categoryLevel3Match.length >
                                                                                              0 &&
                                                                                            !hasCat4
                                                                                              ? "fontStyle"
                                                                                              : categoryLevel4Match.length >
                                                                                                0
                                                                                              ? "tickMark"
                                                                                              : "text-black-50 ml-4"
                                                                                          }
                                                                                        >
                                                                                          {
                                                                                            categoryLevel4.name
                                                                                          }
                                                                                        </span>
                                                                                      </Col>
                                                                                    </Row>
                                                                                  );
                                                                                }
                                                                              )}
                                                                            </div>
                                                                          </Accordion>
                                                                        </Col>
                                                                      </Row>
                                                                    </>
                                                                  )
                                                                ) : (
                                                                  <Row>
                                                                    <Col className="col-md-30">
                                                                      <Accordion
                                                                        color={
                                                                          "WHITE"
                                                                        }
                                                                        data-testid={`standard-accordion-${"SMALL"}-${"WHITE"}`}
                                                                        header={
                                                                          <span className="tickMark">
                                                                            {
                                                                              categoryLevel3.name
                                                                            }
                                                                          </span>
                                                                        }
                                                                        size={
                                                                          "SMALL"
                                                                        }
                                                                        isOpen={
                                                                          hasCat4
                                                                            ? true
                                                                            : false
                                                                        }
                                                                      >
                                                                        <div className="containerViewOnly">
                                                                          <b>
                                                                            Category
                                                                            Level
                                                                            4
                                                                          </b>
                                                                          {categoryLevel3.categoryLevel4s.map(
                                                                            (
                                                                              categoryLevel4,
                                                                              index
                                                                            ) => {
                                                                              return (
                                                                                <Row>
                                                                                  <Col className="col-md-30">
                                                                                    <span className="fontStyle">
                                                                                      {
                                                                                        categoryLevel4.name
                                                                                      }
                                                                                    </span>
                                                                                  </Col>
                                                                                </Row>
                                                                              );
                                                                            }
                                                                          )}
                                                                        </div>
                                                                      </Accordion>
                                                                    </Col>
                                                                  </Row>
                                                                );
                                                              }
                                                            )}
                                                          </div>
                                                        </Accordion>
                                                      </Col>
                                                    </Row>
                                                  </>
                                                )
                                              );
                                            }
                                          )}
                                        </Col>
                                      </Row>
                                    </>
                                  )
                                );
                              }
                            )}
                          </>
                        )
                      );
                    })}
                  </>
                )
              );
            })}
        </div>
      </Form.Group>
    </Form.Row>
  );
}
