import { Card, Button, Form, Col } from "@deere/ux.uxframe-react";
import { useEffect } from "react";
import React from "react";
import VettedSupplierCard from "./VettedSupplierCard";

export default function VettedSuppliersNotIncluded(props) {
  const {
    reasonCodes,
    prospectingTool,
    sdiSuppliers,
    sdfInfo,
    suppliersMap,
    sdiMasterList,
  } = props;
  const {
    control,
    register,
    unregister,
    errors,
    watch,
    setValue,
    reset,
    fields,
    append,
    remove,
  } = props.commonProps;

  const commonProps = {
    register: register,
    unregister: unregister,
    reset: reset,
    errors: errors,
    control: control,
    setValue: setValue,
    watch: watch,
  };

  useEffect(() => {
    if (!sdiSuppliers && !sdfInfo && fields.length === 0) append();
  }, [sdiSuppliers, sdfInfo]);

  return (
    <>
      <h4>Vetted Suppliers Who Were Not Included</h4>
      {fields.length === 0 && (
        <Card>
          <Card.Body>
            <Form.Label
              className="uxf-label"
              style={{ display: "flex", justifyContent: "center" }}
            >
              No Vetted Suppliers Selected Yet
            </Form.Label>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button variant="outline-primary" onClick={() => append()}>
                + Add Vetted Supplier
              </Button>
            </div>
          </Card.Body>
        </Card>
      )}
      {fields.map((item, index) => {
        return (
          <VettedSupplierCard
            key={item.id}
            index={index}
            item={item}
            suppliersMap={suppliersMap}
            supplierType={`items[${index}].supplierType`}
            suppliers={`items[${index}].suppliers`}
            sdis={`items[${index}].sdis`}
            vettedProspectingTool={`items[${index}].vettedProspectingTool`}
            vettedReasonCode={`items[${index}].vettedReasonCode`}
            vettedSupplierComment={`items[${index}].vettedSupplierComment`}
            commonProps={commonProps}
            prospectingTool={prospectingTool}
            reasonCodes={reasonCodes}
            sdiMasterList={sdiMasterList}
            remove={remove}
            allowDelete={
              (sdiSuppliers && sdiSuppliers.size > 0) || fields.length > 1
            }
          />
        );
      })}
      {fields.length > 0 && (
        <Form.Row>
          <Form.Group as={Col}>
            <Button
              className="float-right"
              variant="outline-primary"
              onClick={() => append()}
            >
              + Add Another
            </Button>
          </Form.Group>
        </Form.Row>
      )}
    </>
  );
}
