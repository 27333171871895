import React, { useEffect } from "react";
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  Redirect,
  useHistory,
} from "react-router-dom";
import { oktaAuthConfig } from "./config";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import "@deere/ux.uxframe-core/dist/css/uxframe-2019.3.0.min.css";
import SearchSDForm from "./components/SearchSDForm";
import CreateSDForm from "./components/CreateSDForm";
import StrategySDFormView from "./components/StrategySDFormView";
import NonStrategySDForm from "./components/NonStrategySDForm";
import NonStrategySDFormView from "./components/NonStrategySDFormView";
import SDFormHeader from "./components/SMNavBar";
import "./App.css";
import SMFooter from "./components/SMFooter";
import ErrorBoundary from "./components/ErrorBoundary";
import App404 from "./components/App404";

const oktaAuth = new OktaAuth(oktaAuthConfig);

export default function AppRouter() {
  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ErrorBoundary>
        <SDFormHeader />

        <Switch>
          <Route
            component={LoginCallback}
            exact={true}
            path="/implicit/callback"
          />
            <SecureRoute exact={true} path="/" component={SearchSDForm} />
          <SecureRoute
            exact={true}
            path="/createSDForm/:appName/:strategyId"
            component={CreateSDForm}
          />
          <SecureRoute
            exact={true}
            path="/createSDForm/:strategyId"
            component={CreateSDForm}
          />
          <SecureRoute
            exact={true}
            path="/createSDForm"
            component={NonStrategySDForm}
          />
          <SecureRoute
            exact={true}
            path="/editSDForm/:sdfId/:strategyId"
            component={CreateSDForm}
          />
          <SecureRoute
            exact={true}
            path="/editSDForm/:sdfId/:appName/:strategyId"
            component={CreateSDForm}
          />
          <SecureRoute
            exact={true}
            path="/editSDForm/:sdfId"
            component={NonStrategySDForm}
          />
          <SecureRoute
            exact={true}
            path="/viewSDForm/:sdfId/:strategyId"
            component={StrategySDFormView}
          />
          <SecureRoute
            exact={true}
            path="/viewSDForm/:sdfId/:appName/:strategyId"
            component={StrategySDFormView}
          />
          <SecureRoute
            exact={true}
            path="/viewSDForm/:sdfId"
            component={NonStrategySDFormView}
          />
          <SecureRoute path="/404" exact component={App404} />
          <Redirect to="/404" />
        </Switch>

        <SMFooter />
      </ErrorBoundary>
    </Security>
  );
}
