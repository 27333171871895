import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { Controller } from "react-hook-form";

export default function UnitDropDownSearch(props) {
  const { name, readOnly, defaultValue, unitMap, rules, styles } = props;
  const { control, setValue } = props.commonProps;
  const [arr, setArr] = useState(null);

  const filterOptions = (data, inputValue) => {
    return data.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptions = (inputValue, callback) => {
    if (unitMap) {
      const data = [];

      unitMap.forEach(function (value, key) {
        data.push({
          value: key,
          label: value + " - " + key,
        });
      });
      setArr(data);
      callback(filterOptions(data, inputValue));
    }
  };

  useEffect(() => {
    if (arr && defaultValue) {
      let x = filterOptions(arr, defaultValue).shift();
      setValue(name, x);
    }
  }, [arr]);
  return (
    <Controller
      as={
        <AsyncSelect
          cacheOptions
          styles={styles}
          isDisabled={readOnly}
          isClearable={true}
          loadOptions={loadOptions}
          defaultOptions
          noOptionsMessage={() => "No results found"}
        />
      }
      rules={rules}
      name={name}
      control={control}
    />
  );
}
