import React from "react";
import AppError from "./AppError";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      errorInfo: errorInfo,
    });

    console.log(errorInfo);
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return <AppError errorInfo={this.state.errorInfo} />;
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
