export const jdsnReactSelect = {
  control: (base, state) => ({
    ...base,
    borderRadius: ".125rem",
    boxShadow: state.isFocused ? "0 0 0 0.1875rem rgba(54,124,43,.25)" : 0,
    borderColor: state.isFocused ? "#367c2b" : "#666666",
    "&:hover": {},
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#666",
    "&:hover": {
      color: "#000",
    },
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "2px 8px",
  }),
  menu: (base) => ({
    ...base,
    marginTop: "1px",
    width: "auto",
    borderRadius: ".125rem",
    zIndex: "9999"
  }),
  menuList: (base) => ({
    ...base,
    padding: "0px",
  }),
  option: (base) => ({
    ...base,
    whiteSpace: "nowrap",
    padding: "4px 10px",
  }),
  noOptionsMessage: (base) => ({
    ...base,
    color: "black",
    width: "auto",
  }),
};
