import { useState, useEffect } from "react";

import { Col, Row, Card, Alert, Badge } from "@deere/ux.uxframe-react";
import { fetchJsonWithAuth } from "../shared/fetch-json.util";
import { useIsMounted } from "../shared/useIsMounted";
import {getSdiMap} from "../shared/SDFHelper";

export default function Suppliers(props) {
  const isMounted = useIsMounted();

  const {
    preferredSuppliers,
    setSdiSuppliersForParent,
    sdiMasterList,
    noAlert,
  } = props;
  const {
    incrementAPIInProgress,
    decrementAPIInProgress,
    setStatusErrorMsg,
    tokenAvailable,
  } = props.commonProps;

  const [supplierDetails, setSupplierDetails] = useState(null);
  const [sdiSuppliers, setSdiSuppliers] = useState(null);
  const [sdis, setSdis] = useState(null);

  function populateSupplierDetails(suppliers) {
    if (suppliers && suppliers.length > 0) {
      incrementAPIInProgress();

      fetchJsonWithAuth(process.env.REACT_APP_SUPPLIERS_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({
          supplierNumbers: suppliers,
        }),
      })
        .then(async(res) => {
          if(isMounted.current) {
            let supplierDetails = res.json;
            const sdiMap = await getSdiMap(suppliers);

            supplierDetails = supplierDetails.map((supplier) => ({
                ...supplier,
                sdis: sdiMap.get(supplier.supplierNumber)
              })
            );

            setSupplierDetails(supplierDetails)
          }
        })
        .catch((error) =>
          setStatusErrorMsg(
            "Couldn't load the Supplier Details. Please try again.",
            error
          )
        )
        .finally(() => decrementAPIInProgress());
    }
  }

  useEffect(() => {
    if (preferredSuppliers && tokenAvailable)
      populateSupplierDetails(preferredSuppliers);
  }, [preferredSuppliers, tokenAvailable]);

  useEffect(() => {
    if (supplierDetails) findSDISupplier();
  }, [supplierDetails]);

  function findSDISupplier() {
    let sdiSuppliers = new Map();
    let sdis = new Map();
    let count;

    supplierDetails &&
      supplierDetails.forEach((supplierInfo) => {
        supplierInfo.sdis.forEach((sdi) => {
          if (sdiMasterList && sdiMasterList.get(sdi)) {
            sdiSuppliers.set(supplierInfo.supplierNumber, supplierInfo);
            count = sdis.get(sdi);
            if (!count) count = 0;
            sdis.set(sdi, ++count);
          }
        });
      });
    setSdiSuppliersForParent(sdiSuppliers);
    setSdiSuppliers(sdiSuppliers);
    setSdis(sdis);
  }

  function displaySDI(indicator) {
    let count = sdis && sdis.get(indicator);

    if (!count) count = 0;
    return (
      <span
        key={indicator}
        className={
          sdis && sdis.size > 2
            ? count > 0
              ? "badge badge-success mr-2"
              : "badge badge-secondary mr-2"
            : count !== 0
            ? "badge badge-warning mr-2"
            : "badge badge-secondary mr-2"
        }
      >
        {indicator}
        {count !== 0 && " (" + count + ")"}
      </span>
    );
  }

  return (
    <>
      <h4>
        Suppliers Included In Strategy
        <small> (3 Diversity Indicators Targeted)</small>
      </h4>

      {sdiMasterList &&
        Array.from(sdiMasterList.values()).map((value) => displaySDI(value))}
      {!noAlert &&
        ((preferredSuppliers && preferredSuppliers.length === 0) ||
          (sdis && sdis.size < 3)) && (
          <Alert variant="info" aria-atomic="true" className="my-3">
            <h5 className="uxf-alert-title">
              Target Diversity Indicators Not Met.
            </h5>
            <p className="uxf-alert-description">
              This strategy does not include suppliers with at least 3 diversity
              indicators. If you need support to identify, evaluate and add
              diverse suppliers, please contact{" "}
              <a href="mailto:CorporateSupplierDiversity@JohnDeere.com">
                CorporateSupplierDiversity@JohnDeere.com
              </a>
            </p>
          </Alert>
        )}
      {noAlert && <p />}
      {sdiSuppliers && (
        <Row className={sdis && sdis.size < 3 ? "pt-1" : "pt-3"}>
          {Array.from(sdiSuppliers.values()).map((value) => (
            <Col md="8" className="pb-1" key={value.supplierNumber}>
              <Card>
                <Card.Body className="p-3">
                  <Card.Title>
                    {value.supplierNumber + " - " + value.supplierName}
                  </Card.Title>
                  {value.sdis.map((sdi) => (
                    <Badge
                      bg="secondary"
                      className="mr-1"
                      key={value.supplierNumber + "-" + sdi}
                    >
                      {sdi}
                    </Badge>
                  ))}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
}
