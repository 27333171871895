import { CloseButton } from "@deere/ux.uxframe-react";
import PropTypes from "prop-types";
import React from "react";
import { clearIcon } from "./icon-styles";

function ClearButton(props) {
  return (
    props.isVisible && (
      <button
        className="clear-button"
        data-testid="clear-button"
        disabled={props.disabled}
        onClick={props.onClick}
        type="button"
      >
        <CloseButton />
      </button>
    )
  );
}

ClearButton.displayName = "ClearButton";
ClearButton.propTypes = {
  disabled: PropTypes.bool,
  iconSize: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export default ClearButton;
