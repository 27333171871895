import React, { useState } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  CloseButton,
  Form,
  Checkbox,
  Alert,
  Header,
} from "@deere/ux.uxframe-react";
import { useHistory } from "react-router-dom";
import SMFooter from "./SMFooter";

import {
  browserVersion,
  browserName,
  osName,
  osVersion,
} from "react-device-detect";

import { useUserInfo } from "../shared/UserInfo";
import { getContactUsLink } from "../shared/UtilFunctions";
import {SMALL_BUSINESS_FORM} from "../shared/AppConstants";

export default function AppError(props) {
  const [reportIssue, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [alertMsg, setAlert] = useState(false);
  const info = useUserInfo();
  const loggedInUser = info && info.userId;

  const errorInfo = props.errorInfo;
  function submitIssue() {
    setShow(false);
    setAlert(true);
  }

  const history = useHistory();
  function goBack() {
    history.push("/");
    history.go(0);
  }

  const jdsnAppError = {
    errorMsg: "Some unexpected application error has occurred!",
    user: loggedInUser,
    timestamp: new Date().toLocaleString(),
    opsystem: osName,
    osversion: osVersion,
    browser: browserName,
    appVersion: browserVersion,
    appURL: window.location.href,
    appErrorInfo: errorInfo && errorInfo.componentStack,
  };

  const goHelpDesk = () =>
    window.open(getContactUsLink(loggedInUser, jdsnAppError.appErrorInfo));

  return (
    <>
      <Header
        logoLink="http://deere.com"
        skipLink="#content"
        pageHeading={SMALL_BUSINESS_FORM}
        pageHeadingLink="http://jdsn.deere.com"
      ></Header>
      <main id="content" className="container-fluid bg-gray pt-4 pb-5">
        <Row className="align-items-center h-100">
          <Col md="24" className="col w-100 text-center">
            <Alert show={alertMsg} variant="success" aria-atomic="true">
              <h5 className="uxf-alert-title">
                Your error has been successfully reported.
              </h5>
            </Alert>
            <h1 className="display-6">Sorry, there was an error!</h1>
            <svg
              focusable="false"
              aria-hidden="true"
              fill="#666666"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{ width: "200px", height: "200px" }}
            >
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path>
            </svg>
            <p className="lead">{jdsnAppError.errorMsg}</p>
            <div className="pt-5 pb-5">
              <Button variant="secondary" onClick={goBack}>
                Go Back and Try Again
              </Button>
              <Button variant="primary" onClick={goHelpDesk}>
                Contact Help Desk
              </Button>
            </div>
            <p className="pt-2">
              <span className="font-weight-bold">Error Details</span>
              <br />
              {jdsnAppError.user}, {jdsnAppError.timestamp},{" "}
              {jdsnAppError.opsystem}, {jdsnAppError.browser}
              <br />v{jdsnAppError.appVersion}, {jdsnAppError.appURL}
              <br />
              {jdsnAppError.appErrorInfo}
            </p>
          </Col>
        </Row>
        <Modal show={reportIssue} onHide={handleClose} size="lg" centered>
          <Modal.Header>
            <Modal.Title>Report Issue</Modal.Title>
            <CloseButton onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <p className="lead">The below information will be reported.</p>
            <p className="overflow-auto" style={{ maxHeight: "180px" }}>
              {jdsnAppError.user}, {jdsnAppError.timestamp},{" "}
              {jdsnAppError.opsystem}, {jdsnAppError.browser}
              <br />v{jdsnAppError.appVersion}, {jdsnAppError.appURL}
              <br />
              {jdsnAppError.appErrorInfo}
            </p>
            <Form.Group className="float-right mb-0">
              <Checkbox
                label="Contact me to resolve issue"
                id="contact_me"
                defaultChecked
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={submitIssue}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </main>
      <SMFooter />
    </>
  );
}
