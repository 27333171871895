import React, { useReducer } from "react";

const initialState = {
  success: null,
  successMore: null,
  error: null,
  errorMore: null,
  info: null,
  wait: null,
};

export function useStatusMessages() {
  const reducer = (state, action) => {
    switch (action.type) {
      case "clearAll":
        return initialState;
      case "update":
        return { ...state, ...action.payload };
      default:
        throw new Error();
    }
  };

  const [statusMessages, dispatch] = useReducer(reducer, initialState);
  const clearAllMessages = React.useCallback(
    () => dispatch({ type: "clearAll" }),
    []
  );
  const setStatusSuccessMsg = React.useCallback((msg, desc) => {
    dispatch({ type: "update", payload: { success: msg } });
    if (desc) {
      if (desc) dispatch({ type: "update", payload: { successMore: desc } });
    }
  });

  const parseFetchResponse = (response) =>
    response.json().then((text) => ({
      json: text,
      meta: response,
    }));

  const setStatusErrorMsg = React.useCallback((msg, error) => {
    dispatch({ type: "update", payload: { error: msg } });
    if (error) {
      if (error instanceof Error) {
        if (error.message)
          dispatch({ type: "update", payload: { errorMore: error.message } });
      } else
        parseFetchResponse(error).then((resp) => {
          if (resp.json) {
            let message = "";

            if (resp.json.referenceId)
              message += "ReferenceId = " + resp.json.referenceId;
            if (resp.json.message) message += " Message = " + resp.json.message;

            if (message !== "")
              dispatch({ type: "update", payload: { errorMore: message } });
          }
        });
    }
  });

  const setStatusWarningMsg = React.useCallback((msg, warning) => {
    dispatch({ type: "update", payload: { warning: msg } });
    if (warning) {
      dispatch({ type: "update", payload: { warningMore: warning } });
    }
  });

  const setStatusInfoMsg = React.useCallback(
    (msg) => dispatch({ type: "update", payload: { info: msg } }),
    []
  );
  const setStatusWaitMsg = React.useCallback(
    (msg) => dispatch({ type: "update", payload: { wait: msg } }),
    []
  );
  return {
    statusMessages,
    clearAllMessages,
    setStatusSuccessMsg,
    setStatusErrorMsg,
    setStatusInfoMsg,
    setStatusWaitMsg,
    setStatusWarningMsg,
  };
}
