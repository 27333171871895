import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import BootstrapAccordion from "react-bootstrap/Accordion";
import { FacetBody } from "./FacetBody";
import "../assets/Facet.scss";

export const Facet = ({ children, className, ...props }) => {
  const classes = classNames("uxf-accordion jdsn-facet", className);

  return (
    <BootstrapAccordion bsPrefix={" "} className={classes} {...props}>
      {children}
    </BootstrapAccordion>
  );
};

Facet.propTypes = {
  as: PropTypes.elementType,

  activeKey: PropTypes.string,

  defaultActiveKey: PropTypes.string,
};

Facet.Item = FacetBody;
