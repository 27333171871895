import { useEffect } from "react";
import React from "react";
import { fetchJsonWithAuth } from "../shared/fetch-json.util";

export default function GetUnits(props) {
  const {
    tokenAvailable,
    setStatusErrorMsg,
    incrementAPIInProgress,
    decrementAPIInProgress,
  } = props.commonProps;
  const { setUnitMap } = props;

  const fillList = () => {
    let api = process.env.REACT_APP_UNITS_API_URL;

    incrementAPIInProgress();
    fetchJsonWithAuth(api)
      .then((response) => {
        let map = new Map();

        response.json.forEach((unit) => map.set(unit.code, unit.name));

        setUnitMap(map);
      })
      .catch((error) =>
        setStatusErrorMsg(
          "Couldn't retrieve Unit List. Please try again.",
          error
        )
      )
      .finally((info) => decrementAPIInProgress());
  };

  useEffect(() => {
    if (tokenAvailable) fillList();
  }, [tokenAvailable]);

  return <></>;
}
