import React from "react";
import { Alert, Spinner } from "@deere/ux.uxframe-react";
import { getContactUsLink } from "../shared/UtilFunctions";
import { getLoggedInUserId } from "../shared/fetch-json.util";

export default function MessageBanner({ statusMessages, apiInProgressCount }) {
  let statusSuccessMsg = statusMessages.success;
  let statusSuccessMoreMsg = statusMessages.successMore;
  let statusErrorMsg = statusMessages.error;
  let statusErrorMoreMsg = statusMessages.errorMore;
  let statusWarningMsg = statusMessages.warning;
  let statusWarningMoreMsg = statusMessages.warningMore;
  let statusErrorMoreMsgPresent =
    statusErrorMoreMsg && statusErrorMoreMsg.length > 0;
  let statusInfoMsg = statusMessages.info;
  let statusWaitMsg = statusMessages.wait
    ? statusMessages.wait
    : "Please Wait...";

  function goHelpDesk(errorInfo) {
    window.open(getContactUsLink(getLoggedInUserId(), errorInfo));
  }
  return (
    <>
      {statusSuccessMsg && (
        <Alert variant="success" aria-atomic="true">
          <h5 className="uxf-alert-title">{statusSuccessMsg}</h5>
          {statusSuccessMoreMsg && (
            <p className="uxf-alert-description">{statusSuccessMoreMsg}</p>
          )}
        </Alert>
      )}
      {statusErrorMsg && (
        <Alert variant="danger" aria-atomic="true">
          <h5 className="uxf-alert-title">
            {statusErrorMoreMsgPresent && "Sorry, there was an error - "}
            {statusErrorMsg}
          </h5>
          {statusErrorMoreMsgPresent && (
            <p className="uxf-alert-description">
              Error information : [{statusErrorMoreMsg}]
              <br /> Click{" "}
              <a href="#" onClick={(e) => goHelpDesk(statusErrorMoreMsg)}>
                report this issue
              </a>{" "}
              to help resolve the problem.
            </p>
          )}
        </Alert>
      )}
      {statusWarningMsg && (
        <Alert variant="danger" aria-atomic="true">
          <h5 className="uxf-alert-title">{statusWarningMsg}</h5>
          {statusWarningMoreMsg && (
            <p className="uxf-alert-description">{statusWarningMoreMsg}</p>
          )}
        </Alert>
      )}
      {statusInfoMsg && (
        <Alert variant="info" aria-atomic="true">
          <h5 className="uxf-alert-title">{statusInfoMsg}</h5>
        </Alert>
      )}
      {apiInProgressCount.count > 0 && (
        <div className="uxf-spinner-border-inline mt-4">
          <Spinner className="uxf-spinner-border-sm" animation="border" />
          <p className="uxf-spinner-border-inline-text">{statusWaitMsg}</p>
        </div>
      )}
    </>
  );
}
