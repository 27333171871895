import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import {
  Card,
  Button,
  IconButton,
  Popover,
  OverlayTrigger,
} from "@deere/ux.uxframe-react";
import { ReactComponent as ChevronRightIcon } from "@deere/ux.brand-foundations/icons/chevron_right.svg";
import { ReactComponent as CloseIcon } from "@deere/ux.brand-foundations/icons/close.svg";

export const FacetBody = ({
  children,
  selectedItems,
  hoverTextonTitle,
  clearSelection,
  className,
  title,
  id,
  donotShowClearButtonOnOpen,
  ...props
}) => {
  const contextEventKey = useContext(AccordionContext);
  const classes = classNames("shadow-none", className);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Card className={classes} {...props}>
      <Card.Header className="bg-white">
        <h5 className="uxf-accordion-item d-flex justify-content-between align-items-center">
          <Accordion.Toggle
            as={Button}
            variant="link"
            aria-expanded={contextEventKey === id}
            aria-controls={id}
            eventKey={id}
            onClick={handleClick}
          >
            <ChevronRightIcon className="uxf-accordion-icon" />
            {hoverTextonTitle ? (
              <OverlayTrigger
                rootClose={true}
                overlay={
                  <Popover>
                    <Popover.Content>{hoverTextonTitle}</Popover.Content>
                  </Popover>
                }
              >
                <span className="uxf-accordion-title">{title}</span>
              </OverlayTrigger>
            ) : (
              <span className="uxf-accordion-title">{title}</span>
            )}
          </Accordion.Toggle>

          {/* start: only show if at least 1 input option is selected */}
          {!donotShowClearButtonOnOpen &&
            selectedItems &&
            selectedItems.length > 0 && (
              <IconButton
                ariaLabel="clearAll"
                className="d-block mr-2"
                onClick={clearSelection}
              >
                <CloseIcon />
              </IconButton>
            )}
        </h5>
        {!isOpen && selectedItems && selectedItems.length > 0 && (
          <ul
            className="checkbox pl-1 mb-2"
            style={{ overflowY: "auto", maxHeight: "200px" }}
          >
            {selectedItems.map((element) =>
              element.children ? (
                element.children.map((record) => (
                  <li key={record} title={record} className="text-truncate">
                    <span className="text-black-50">{element.parent}</span>
                    {" - "}
                    {record}
                  </li>
                ))
              ) : (
                <li key={element.parent}> {element.parent}</li>
              )
            )}
          </ul>
        )}
      </Card.Header>

      <Accordion.Collapse id={id} eventKey={id}>
        <Card.Body className="pl-3 pr-2 pb-3">{children}</Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

FacetBody.propTypes = {
  title: PropTypes.string.isRequired,

  id: PropTypes.string.isRequired,

  summary: PropTypes.node,
};
