import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { Controller } from "react-hook-form";
import { jdsnReactSelect } from "../shared/jdsnReactSelect";
import { fetchJsonWithAuth } from "../shared/fetch-json.util";
import {getSdiMap} from "../shared/SDFHelper";

export default function SupplierDropDownSearch(props) {
  const {
    name,
    setSelectedSupplier,
    readOnly,
    setStatusErrorMsg,
    defaultValue,
  } = props;
  const { control } = props.commonProps;
  const [isloading, setIsloading] = useState(false);

  const filterOptions = (users, inputValue) => {
    let lowerCaseInputValue = inputValue.toLowerCase();
    let item = users.filter(
      (i) =>
        i.sdis.length > 0 && i.label.toLowerCase().includes(lowerCaseInputValue)
    );
    return item;
  };

  const loadOptions = (inputValue, callback) => {
    if (inputValue.length > 2) {
      setIsloading(true);
      setStatusErrorMsg(null);
      fetchJsonWithAuth(
        process.env.REACT_APP_SUPPLIERS_API_URL +
          `?supplierNo=` +
          escape(inputValue)
      )
        .then(async (res) => {
          const result = res.json;
          const sdiMap = await getSdiMap(result.map((res)=> res.supplierNumber));

          const data = result.map((item) => {
            return {
              value: item.supplierNumber,
              label: item.supplierNumber + " - " + item.supplierName,
             sdis: sdiMap.get(item.supplierNumber),
            };
          });
          callback(filterOptions(data, inputValue));
          setIsloading(false);
        })
        .catch((error) => {
          setStatusErrorMsg("Couldn't fetch Suppliers.", error);
          setIsloading(false);
        });
    } else callback(null);
  };

  const clearSDI = (inputValue) => {
    if (inputValue === "") setSelectedSupplier(null);
  };

  return (
    <Controller
      as={
        <AsyncSelect
          aria-label="Please Enter 3 or more Characters"
          cacheOptions
          isClearable={true}
          loadOptions={loadOptions}
          openMenuOnClick={false}
          isDisabled={readOnly}
          styles={jdsnReactSelect}
          isLoading={isloading}
          placeholder="Search (enter 3 or more characters)"
          noOptionsMessage={() => "No results found"}
          getOptionValue={(value) => setSelectedSupplier(value)}
          onInputChange={(value) => clearSDI(value)}
        />
      }
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
}
