import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

export const AccordionIcon = (props) => {
  const {
    className,
    iconClosed,
    iconOpen,
    iconCollapsed,
    isOpen,
    cbSelected,
    ...extraProps
  } = props;

  return (
    <span
      className={classNames(className)}
      data-testid="accordion-icon"
      {...extraProps}
    >
      {isOpen ? iconOpen : iconClosed}
    </span>
  );
};

AccordionIcon.propTypes = {
  className: PropTypes.string,
  iconClosed: PropTypes.node.isRequired,
  iconOpen: PropTypes.node.isRequired,
  iconCollapsed: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  cbSelected: PropTypes.bool.isRequired,
};

AccordionIcon.defaultProps = {
  className: "",
};
