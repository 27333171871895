import React from "react";

import { Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { jdsnReactSelect } from "../shared/jdsnReactSelect";

export default function SDIManual(props) {
  const { name, defaultValue, sdiMasterList } = props;
  const { control } = props.commonProps;

  const filterOptions = (data, inputValue) => {
    return data.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const loadOptions = (inputValue, callback) => {
    if (sdiMasterList) {
      const data = Array.from(sdiMasterList.values()).map((item) => {
        return {
          value: item,
          label: item,
        };
      });
      callback(filterOptions(data, inputValue));
    }
  };

  return (
    <Controller
      as={
        <AsyncSelect
          cacheOptions
          styles={jdsnReactSelect}
          loadOptions={loadOptions}
          defaultOptions
          isMulti={true}
          noOptionsMessage={() => "No results found"}
        />
      }
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
}
