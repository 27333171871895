import {
  Icon,
  iconColors,
  iconSizes,
} from "@deere/cap-it-ui.sales-center-icons";
import { BaseAccordion } from "./base-accordion";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import styles from "./accordion.module.scss";

export const Accordion = (props) => {
  const {
    AccordionBodyProps,
    AccordionHeaderProps,
    children,
    className,
    color,
    IconClosedProps,
    IconOpenProps,
    IconCollapsedProps,
    cbSelected,
    size,
    ...extraProps
  } = props;

  const iconClosed = (
    <Icon
      color={iconColors.chevronRight.DARK_GRAY}
      size={iconSizes.LARGE}
      type="chevron-right"
      {...IconClosedProps}
    />
  );

  const iconCollapsed = (
    <Icon
      color={iconColors.chevronRight.DARK_GRAY}
      size={iconSizes.LARGE}
      type="chevron-right"
      {...IconCollapsedProps}
    />
  );

  const iconOpen = (
    <Icon
      color={iconColors.chevronDown.DARK_GRAY}
      size={iconSizes.LARGE}
      type="chevron-down"
      {...IconOpenProps}
    />
  );

  const classes = classNames(className, styles.accordion, {
    [styles.large]: size === "LARGE",
    [styles.small]: size === "SMALL",
    [styles.gray]: color === "GRAY",
    [styles.white]: color === "WHITE",
  });

  return (
    <BaseAccordion
      AccordionBodyProps={{
        ...AccordionBodyProps,
        className: classNames(
          styles.accordionBody,
          AccordionBodyProps.className
        ),
      }}
      AccordionHeaderProps={{
        ...AccordionHeaderProps,
        className: classNames(
          styles.accordionHeader,
          AccordionHeaderProps.className
        ),
      }}
      className={classes}
      iconClosed={iconClosed}
      iconOpen={iconOpen}
      iconCollapsed={iconCollapsed}
      cbSelected={cbSelected}
      {...extraProps}
    >
      {children}
    </BaseAccordion>
  );
};

Accordion.propTypes = {
  AccordionBodyProps: PropTypes.object,
  AccordionHeaderProps: PropTypes.object,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(["GRAY", "WHITE"]),
  header: PropTypes.node.isRequired,
  IconClosedProps: PropTypes.object,
  IconOpenProps: PropTypes.object,
  IconCollapsedProps: PropTypes.object,
  isOpen: PropTypes.bool,
  cbSelected: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["LARGE", "SMALL"]),
};

Accordion.defaultProps = {
  AccordionBodyProps: {},
  AccordionHeaderProps: {},
  color: "WHITE",
  size: "LARGE",
};
