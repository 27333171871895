import React from "react";
import BaseInputFilter from "./base-input-filter";
import ClearButton from "./clear-button";
import { iconSizeSmall } from "./icon-styles";
import { baseDefaultProps, inputFilterPropTypes } from "./prop-types";

export default function InputFilter(props) {
  const controls = props.value ? (
    <ClearButton
      disabled={props.disabled}
      iconSize={iconSizeSmall}
      isVisible={true}
      onClick={props.onClear}
    />
  ) : null;

  return (
    <BaseInputFilter
      {...props}
      className={`cap-it-ui-input-filter ${props.className}`}
      controls={controls}
    />
  );
}

InputFilter.displayName = "InputFilter";
InputFilter.propTypes = inputFilterPropTypes;
InputFilter.defaultProps = baseDefaultProps;
