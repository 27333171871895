import {
  FORM_TYPE_IN_STRATEGY,
  FORM_TYPE_NOT_IN_STRATEGY,
} from "../shared/AppConstants";

import {
  Form,
  Col,
  ToggleButtonGroup,
  ToggleButton,
} from "@deere/ux.uxframe-react";

export default function StrategyOrSinglePO(props) {
  const { formType } = props;

  return (
    <Form.Group as={Col} xs={15} md={10}>
      <ToggleButtonGroup
        role="toolbar"
        includeLabel
        className="mt-3"
        label="Is this for a Strategy or a single purchase (not in
                      Strategy)?"
        name="radioButtonGroup"
        type="radio"
        value={formType === FORM_TYPE_IN_STRATEGY ? 1 : 2}
        ariaLabel="Is this for a Strategy or a single purchase (not in
                        Strategy)?"
      >
        <ToggleButton value={1} disabled={formType !== FORM_TYPE_IN_STRATEGY}>
          In Strategy
        </ToggleButton>
        <ToggleButton
          value={2}
          disabled={formType !== FORM_TYPE_NOT_IN_STRATEGY}
        >
          Not In Strategy
        </ToggleButton>
      </ToggleButtonGroup>
    </Form.Group>
  );
}
