import React, { useState, useEffect } from "react";
import { fetchJsonWithAuth } from "../shared/fetch-json.util";
import { Facet } from "../shared/Facet";
import { NOT_AVAILABLE } from "../shared/AppConstants";
import { Checkbox } from "@deere/ux.uxframe-react/lib/components/Checkbox";
import { ListFilter } from "../shared/ListFilter/index";
import { callUserInfoAPI } from "../shared/SDFHelper";

export default function NonStrategyUpdaterFilter(props) {
  const filterId = "non_stratgy_updater_filter";
  const {
    setStatusErrorMsg,
    tokenAvailable,
    decrementAPIInProgress,
    incrementAPIInProgress,
    setParentFilter,
  } = props.commonProps;
  const info = props.info;
  const { setSelectedNonStrategyUpdaterIds, selectedValues, setUserMapFn } =
    props;

  const [tmpUserList, setTmpUserList] = useState(null);
  const [userList, setUserList] = useState(null);
  const [selectedLabels, setSelectedLabels] = useState(null);

  const loadOptions = () => {
    let api = process.env.REACT_APP_SDF_API_URL + "/non-strategy-form-updaters";

    incrementAPIInProgress();
    fetchJsonWithAuth(api)
      .then((response) => {
        const result = response.json;

        if (result && result.length > 0) {
          callUserInfoAPI(
            result,
            setUserMapFn,
            setTmpUserList,
            incrementAPIInProgress,
            decrementAPIInProgress,
            setStatusErrorMsg
          );
        }
      })
      .catch((error) =>
        setStatusErrorMsg("Couldn't fetch Non Strategy Form Updaters.", error)
      )
      .finally((info) => decrementAPIInProgress());
  };

  useEffect(() => {
    if (tmpUserList) {
      // Sort by Last Name
      tmpUserList.sort((a, b) => a.lastName.localeCompare(b.lastName));

      const data = tmpUserList.map((item) => {
        return {
          id: item.id,
          name:
            (item.lastName !== NOT_AVAILABLE ? item.lastName : "") +
            (item.firstName !== NOT_AVAILABLE ? ", " + item.firstName : "") +
            (item.middleInitial !== NOT_AVAILABLE && item.middleInitial !== null
              ? " " + item.middleInitial + " - "
              : "") +
            item.id.toUpperCase(),
          checked: false,
        };
      });
      setUserList(data);
    }
  }, [tmpUserList]);

  useEffect(() => {
    if (userList) {
      let obj;

      if (selectedValues) {
        selectedValues.forEach((element) => {
          obj = userList.find((item) => item.id === element);
          if (obj) obj.checked = true;
        });
      } else userList.forEach((obj) => (obj.checked = false));
      processChange(false);
    }
  }, [selectedValues, userList, info]);

  useEffect(() => {
    if (tokenAvailable) loadOptions();
  }, [tokenAvailable]);

  function processChange(setONParent) {
    let checkedOwnerParent = "";
    let checkedOwner = [];
    let checkedOwnerObjList = [];

    userList &&
      userList.forEach((element) => {
        if (element.checked) {
          checkedOwner.push({ parent: element.name });
          checkedOwnerObjList.push(element);
          checkedOwnerParent +=
            checkedOwnerParent === "" ? element.id : "," + element.id;
        }
      });
    setSelectedLabels(checkedOwner);
    setSelectedNonStrategyUpdaterIds(checkedOwnerParent);
    setONParent &&
      setParentFilter &&
      setParentFilter({ ownerFilter: checkedOwnerObjList });
  }

  const toggleSelectedFilter = (owner) => {
    userList.forEach((element) => {
      if (owner.name === element.name) element.checked = !element.checked;
    });
    processChange(true);
  };

  const clearSelection = () => {
    userList.forEach((record) => (record.checked = false));
    processChange(true);
  };

  function filterHandler(index, text) {
    const targetItem = userList[index];
    const filterText = text ? text.toLowerCase() : "";

    if (filterText === "") return true;

    let array = filterText.replace(",", " ").split(" ");
    let isPresent = true;

    array.forEach((item) => {
      if (item !== "")
        isPresent =
          isPresent && targetItem.name.toLowerCase().indexOf(item) > -1;
    });
    return isPresent;
  }

  return (
    <Facet>
      <Facet.Item
        id={filterId}
        title="Last Updated by"
        selectedItems={selectedLabels}
        clearSelection={clearSelection}
      >
        {userList && (
          <ListFilter filter={filterHandler} placeholder="Search" inputLabel="">
            <ul>
              {userList.map((user) => {
                return (
                  <li key={filterId + user.id}>
                    <Checkbox
                      id={filterId + user.id}
                      label={user.name}
                      checked={user.checked}
                      onChange={() => toggleSelectedFilter(user)}
                    ></Checkbox>
                  </li>
                );
              })}
            </ul>
          </ListFilter>
        )}
      </Facet.Item>
    </Facet>
  );
}
