import React, { Component } from "react";
import BaseInputFilter from "./base-input-filter";
import ClearButton from "./clear-button";
import { iconSizeSmall } from "./icon-styles";
import { baseDefaultProps, searchInputFilterPropTypes } from "./prop-types";

const noOp = () => undefined;

export default class SearchInputFilter extends Component {
  constructor(props) {
    super(props);

    this.value = props.value;
    this.selectedConstraint = props.selectedConstraint;

    this.handleSearch = () => {
      this.props.onSearch(this.value, this.selectedConstraint);
    };

    this.handleOnChange = (event, ...args) => {
      this.value = event.target.value;
      this.props.onChange(event, ...args);
    };

    this.handleOnClear = (...args) => {
      this.value = "";
      this.props.onClear(...args);
    };

    this.handleConstraintChange = (event, selectedConstraint) => {
      this.selectedConstraint = selectedConstraint;
      this.props.onConstraintChange(event, selectedConstraint);
    };
  }

  render() {
    return (
      <BaseInputFilter
        {...this.props}
        className={`cap-it-ui-search-input-filter ${this.props.className}`}
        controls={[
          <ClearButton
            disabled={this.props.disabled}
            iconSize={iconSizeSmall}
            isVisible={Boolean(this.props.value)}
            key="clear-button"
            onClick={this.handleOnClear}
          />,
        ]}
        onChange={this.handleOnChange}
        onSearch={this.handleSearch}
      />
    );
  }
}

SearchInputFilter.displayName = "SearchInputFilter";
SearchInputFilter.propTypes = searchInputFilterPropTypes;
SearchInputFilter.defaultProps = {
  ...baseDefaultProps,
  autoFocus: true,
  constraintsMenu: [],
  onConstraintChange: noOp,
};
