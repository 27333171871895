import { FORM_TYPE_IN_STRATEGY } from "../shared/AppConstants";

import { Form, Col } from "@deere/ux.uxframe-react";
import Scope from "./Scope";

export default function DMSMScope(props) {
  const { formType, strategyInfo } = props;

  return (
    formType === FORM_TYPE_IN_STRATEGY && (
      <Form.Group as={Col} xs={12} md={8}>
        <Form.Label className="uxf-label">Unit or Unit Groups</Form.Label>

        {strategyInfo && (
          <Scope
            unitGroups={strategyInfo.unitGroup}
            units={strategyInfo.units}
          />
        )}
      </Form.Group>
    )
  );
}
