import { useEffect } from "react";
import React from "react";
import { fetchJsonWithAuth } from "../shared/fetch-json.util";

export default function GetMasterScopes(props) {
  const {
    tokenAvailable,
    setStatusErrorMsg,
    incrementAPIInProgress,
    decrementAPIInProgress,
    setMasterScopeJson,
    setMasterScopeMap,
  } = props.commonProps;

  const fillScopeData = () => {
    let api = process.env.REACT_APP_DMSM_SCOPE_API_URL;

    incrementAPIInProgress();
    fetchJsonWithAuth(api)
      .then((response) => {
        setMasterScopeJson(response.json);

        let scopeMap = new Map();

        response.json.unitGroups.forEach((scope) => {
          scopeMap.set(scope.code, scope.name);
          scope.units.forEach((unit) => {
            scopeMap.set(unit.code, unit.name + " - " + unit.code);
          });
        });
        setMasterScopeMap(scopeMap);
      })
      .catch((error) =>
        setStatusErrorMsg(
          "Couldn't retrieve Master Scope info. Please try again.",
          error
        )
      )
      .finally((info) => decrementAPIInProgress());
  };

  useEffect(() => {
    if (tokenAvailable) {
      fillScopeData();
    }
  }, [tokenAvailable]);

  return <></>;
}
