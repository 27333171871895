import React, { Component } from "react";
import { AccordionBody } from "./accordion-body";
import { AccordionHeader } from "./accordion-header";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./base-accordion.module.scss";

export class BaseAccordion extends Component {
	state = {
		isOpen: this.props.isOpen,
	};

	componentDidUpdate = (prevProps) => {
		if (prevProps.isOpen !== this.props.isOpen) {
			this.setState({
				// eslint-disable-line react/no-did-update-set-state
				isOpen: this.props.isOpen,
			});
		}
	};

	handleClick = () => {
		const isOpen = !this.state.isOpen;

		this.props.onClick(isOpen);
		this.setState({
			isOpen,
		});
	};

	render = () => {
		const {
			AccordionBodyProps,
			AccordionHeaderProps,
			children,
			className,
			header,
			iconClosed,
			iconOpen,
			iconCollapsed,
			isOpen,
			onClick,
			cbSelected,
			position,
			...extraProps
		} = this.props;

		const accordionClasses = classNames(
			{
				[styles.isOpen]: this.state.isOpen,
				open: this.state.isOpen,
				[styles.right]: position === "right",
				[styles.left]: position === "left",
			},
			className
		);

		return (
			<div className={accordionClasses} data-testid="accordion" {...extraProps}>
				<AccordionHeader
					AccordionIconProps={{
						className: styles.accordionIcon,
					}}
					iconClosed={iconClosed}
					iconOpen={iconOpen}
					isOpen={this.state.isOpen}
					cbSelected={this.state.cbSelected}
					onClick={this.handleClick}
					{...AccordionHeaderProps}
					className={classNames(
						styles.accordionHeader,
						AccordionHeaderProps.className
					)}
				>
					{header}
				</AccordionHeader>
				<AccordionBody isOpen={this.state.isOpen} {...AccordionBodyProps}>
					{children}
				</AccordionBody>
			</div>
		);
	};
}

BaseAccordion.propTypes = {
	AccordionBodyProps: PropTypes.object,
	AccordionHeaderProps: PropTypes.object,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	header: PropTypes.node.isRequired,
	iconClosed: PropTypes.node.isRequired,
	iconOpen: PropTypes.node.isRequired,
	IconCollapsed: PropTypes.node.isRequired,
	isOpen: PropTypes.bool,
	cbSelected: PropTypes.bool,
	onClick: PropTypes.func,
	position: PropTypes.oneOf(["left", "right"]),
};

BaseAccordion.defaultProps = {
	AccordionHeaderProps: {},
	className: "",
	isOpen: false,
	onClick: () => undefined,
	position: "left",
	cbSelected: false,
};
