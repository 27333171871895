import { Col, Form, Card, Badge, Button } from "@deere/ux.uxframe-react";
import React, { useState } from "react";
import SupplierDropDownSearchManual from "./SupplierDropDownSearchManual";

import SupplierDropDownSearch from "./SupplierDropDownSearch";
import { useStatusMessages } from "../shared/StatusMessages";
import { useEffect } from "react";

import {
  CURRENT_SUPPLIER,
  NON_CURRENT_OR_PROSPECTIVE_SUPPLIER,
  OTHER_REASON,
  CURRENT,
  PROSPECTIVE,
} from "../shared/AppConstants";
import { ReactComponent as DeleteIcon } from "@deere/ux.brand-foundations/icons/delete.svg";
import SDIManual from "./SDIManual";

export default function VettedSupplierCard(props) {
  const { register, setValue } = props.commonProps;
  const {
    item,
    prospectingTool,
    reasonCodes,
    suppliersMap,
    supplierType,
    suppliers,
    sdis,
    vettedProspectingTool,
    vettedReasonCode,
    sdiMasterList,
    vettedSupplierComment,
    index,
    remove,
    allowDelete,
  } = props;

  const { setStatusErrorMsg } = useStatusMessages();
  const [supplierSelection, setSupplierSelection] = useState(null);
  const [reasonCodeType, setReasonCodeType] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [defValSelectedSDIs, setDefValSelectedSDIs] = useState(null);
  const [defValSelectedSupplier, setDefValSelectedSupplier] = useState(null);
  const [selectedSuppliersSDI, setSelectedSuppliersSDI] = useState([]);

  function getStatusOptions() {
    return (
      <>
        <option value={"Select"} disabled selected></option>
        <option value={CURRENT}>{CURRENT_SUPPLIER}</option>
        <option value={PROSPECTIVE}>
          {NON_CURRENT_OR_PROSPECTIVE_SUPPLIER}
        </option>
      </>
    );
  }

  function getBadges() {
    let itemList = [];

    selectedSupplier &&
      selectedSupplier.sdis &&
      selectedSupplier.sdis.forEach((item) =>
        itemList.push(
          <Badge key={item} className="ml-2 mr-1" variant="secondary">
            {item}
          </Badge>
        )
      );

    setSelectedSuppliersSDI(itemList);
  }
  useEffect(() => getBadges(selectedSupplier), [selectedSupplier]);

  function supplierSelectionUpdated() {
    setSelectedSupplier(null);
    setSelectedSuppliersSDI(null);
    setDefValSelectedSupplier(null);
    setDefValSelectedSDIs(null);
  }

  function getSDIsLabelAndValue(sdis) {
    let array = [];

    sdis &&
      sdis.forEach((sdiItem) =>
        array.push({
          label: sdiItem.sdi,
          value: sdiItem.sdi,
        })
      );
    return array;
  }
  useEffect(() => {
    if (item && item.nameOrNumber) {
      setSupplierSelection(item.supplierType);

      if (item.supplierType === PROSPECTIVE) {
        let tmpSdis = getSDIsLabelAndValue(item.vettedProspectiveSupplierSDIS);

        setValue(suppliers, item.nameOrNumber);
        setDefValSelectedSupplier(item.nameOrNumber);
        setDefValSelectedSDIs(tmpSdis);
        setValue(sdis, tmpSdis);
      } else {
        let supplierInfo = suppliersMap && suppliersMap.get(item.nameOrNumber);

        setSelectedSupplier(supplierInfo);
        setDefValSelectedSupplier(supplierInfo);
        setValue(suppliers, supplierInfo);
      }

      setValue(supplierType, item.supplierType);
      setValue(vettedProspectingTool, item.prospectingTool);
      setValue(vettedReasonCode, item.reasonCode);
      setValue(vettedSupplierComment, item.comments);
      setReasonCodeType(item.reasonCode);
    }
  }, [reasonCodes, prospectingTool, item, suppliersMap]);

  return (
    <>
      <Card>
        <Card.Body>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="uxf-label">
                Supplier Type{" "}
                <span aria-label="required field" className="uxf-is-required">
                  *
                </span>
              </Form.Label>

              <Form.Control
                as="select"
                ref={register()}
                name={supplierType}
                defaultValue={item.supplierType}
                onChange={(e) => {
                  setValue(suppliers, "");
                  setValue(sdis, "");
                  setSupplierSelection(e.target.value);
                  supplierSelectionUpdated();
                }}
              >
                {getStatusOptions()}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="uxf-label">
                {supplierSelection === PROSPECTIVE
                  ? "Supplier Name"
                  : "Supplier"}{" "}
                <span aria-label="required field" className="uxf-is-required">
                  *
                </span>
              </Form.Label>
              {supplierSelection === PROSPECTIVE && (
                <SupplierDropDownSearchManual
                  readOnly={!supplierSelection}
                  name={suppliers}
                  key={"SupplierDropDownSearchManual-" + defValSelectedSupplier}
                  setStatusErrorMsg={setStatusErrorMsg}
                  setSelectedSupplier={setSelectedSupplier}
                  commonProps={props.commonProps}
                  defaultValue={defValSelectedSupplier}
                />
              )}
              {supplierSelection === CURRENT && (
                <SupplierDropDownSearch
                  readOnly={!supplierSelection}
                  name={suppliers}
                  key={"SupplierDropDownSearch-" + defValSelectedSupplier}
                  setStatusErrorMsg={setStatusErrorMsg}
                  setSelectedSupplier={setSelectedSupplier}
                  commonProps={props.commonProps}
                  defaultValue={defValSelectedSupplier}
                />
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="uxf-label">
                Supplier Diversity Indicator (SDI) Type{" "}
                <span aria-label="required field" className="uxf-is-required">
                  *
                </span>
              </Form.Label>
              <br />
              {sdiMasterList && supplierSelection === PROSPECTIVE ? (
                <SDIManual
                  name={sdis}
                  key={defValSelectedSDIs}
                  commonProps={props.commonProps}
                  defaultValue={defValSelectedSDIs}
                  sdiMasterList={sdiMasterList}
                />
              ) : (
                selectedSuppliersSDI
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            {prospectingTool && (
              <Form.Group as={Col} xs={10} md={8}>
                <Form.Label className="uxf-label">
                  Prospecting Tool{" "}
                  <span aria-label="required field" className="uxf-is-required">
                    *
                  </span>
                </Form.Label>
                <Form.Control
                  as="select"
                  ref={register()}
                  name={vettedProspectingTool}
                  defaultValue={item.prospectingTool}
                >
                  <option value={"Select"}> </option>
                  {prospectingTool.map((option) => (
                    <option key={option.code} value={option.code}>
                      {option.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}
            <Form.Group as={Col}>
              <Form.Label className="uxf-label">
                Reason Code{" "}
                <span aria-label="required field" className="uxf-is-required">
                  *
                </span>
              </Form.Label>
              {reasonCodes && (
                <Form.Control
                  as="select"
                  ref={register()}
                  name={vettedReasonCode}
                  defaultValue={item.reasonCode}
                  onChange={(e) => setReasonCodeType(e.target.value)}
                >
                  <option value={"Select"}> </option>
                  {reasonCodes.map((option) => (
                    <option key={option.code} value={option.code}>
                      {option.name}
                    </option>
                  ))}
                </Form.Control>
              )}
            </Form.Group>
          </Form.Row>
          {reasonCodeType && reasonCodeType === OTHER_REASON && (
            <Form.Row>
              <Form.Group as={Col} xs={10} md={8}></Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="uxf-label">
                  Comments{" "}
                  <span aria-label="required field" className="uxf-is-required">
                    *
                  </span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  aria-label="Comment"
                  ref={register()}
                  maxLength="512"
                  name={vettedSupplierComment}
                  defaultValue={item.comments}
                  onBlur={(e) => (e.target.value = e.target.value.trim())}
                />
              </Form.Group>
            </Form.Row>
          )}
          <Form.Row>
            <Form.Group as={Col}></Form.Group>
            <Form.Group as={Col} xs={4} md={5}>
              {allowDelete && (
                <Button
                  style={{ marginTop: "27px" }}
                  variant="outline-dark"
                  className="float-right btn-outline-uxf-charcoal"
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <DeleteIcon />
                  Remove Supplier
                </Button>
              )}
            </Form.Group>
          </Form.Row>
        </Card.Body>
      </Card>
      <br />
    </>
  );
}
