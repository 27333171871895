import PropTypes from "prop-types";
import React, { Component } from "react";
import "../styles/list-filter.scss";
import SearchInputFilter from "./search-input-filter";

function getChildList() {
	return React.Children.only(this.props.children);
}

function getGrandChildren() {
	const childList = getChildList.call(this);

	return childList.props.children;
}

function getItemVisibility(filterText) {
	const itemVisibility = {};
	const grandChildren = getGrandChildren.call(this);

	React.Children.forEach(
		grandChildren,
		function (listItem, index) {
			itemVisibility[index] = this.props.filter(index, filterText);
		},
		this
	);

	return itemVisibility;
}

function findVisibleItems(inputValue) {
	const itemVisibility = getItemVisibility.call(this, inputValue);
	const newVisibleItems = Object.keys(itemVisibility).reduce(
		(filteredInIndexes, item, index) => {
			return itemVisibility[item]
				? filteredInIndexes.concat([index])
				: filteredInIndexes;
		},
		[]
	);

	this.props.onChange(inputValue, newVisibleItems);
	this.setState({
		inputValue,
		itemVisibility,
	});
}

function filterChangeHandler(event) {
	const inputValue = event.target.value;

	findVisibleItems.call(this, inputValue);
}

function reset() {
	const inputValue = "";

	findVisibleItems.call(this, inputValue);
}

function determineVisibility(index) {
	return this.state.itemVisibility[index]
		? {}
		: {
				display: "none",
		  };
}

function updateVisibilityOf(grandChildren) {
	return React.Children.map(
		grandChildren,
		function (listItem, index) {
			const style = determineVisibility.call(this, index);

			return React.cloneElement(listItem, {
				style,
			});
		},
		this
	);
}

function renderListBasedOnFilter() {
	const grandChildren = getGrandChildren.call(this);
	const childList = getChildList.call(this);
	const updatedGrandChildren = updateVisibilityOf.call(this, grandChildren);

	return React.cloneElement(childList, null, updatedGrandChildren);
}

function isViewStateEmpty() {
	return Object.keys(this.state.itemVisibility).length === 0;
}

function getFilterChangeHandler() {
	return filterChangeHandler.bind(this);
}

function getReset() {
	return reset.bind(this);
}

export class ListFilter extends Component {
	constructor(props) {
		super(props);

		const inputValue = props.inputValue ? props.inputValue : "";

		this.state = {
			inputValue,
			itemVisibility: getItemVisibility.call(this, inputValue),
		};
	}

	render() {
		const { children, inputLabel, placeholder } = this.props;
		const list = isViewStateEmpty.call(this)
			? children
			: renderListBasedOnFilter.call(this);

		return (
			<form className="filter">
				<SearchInputFilter
					className={inputLabel}
					label={inputLabel}
					onChange={getFilterChangeHandler.call(this)}
					onClear={getReset.call(this)}
					placeholder={placeholder}
					value={this.state.inputValue}
				/>
				{list}
			</form>
		);
	}
}

ListFilter.propTypes = {
	children: PropTypes.element.isRequired,
	inputLabel: PropTypes.string.isRequired,
	inputValue: PropTypes.string,
	onChange: PropTypes.func,
	placeholder: PropTypes.string.isRequired,
};

ListFilter.defaultProps = {
	onChange: () => undefined,
};
