import React from "react";
import { Modal, Button, Alert, CloseButton } from "@deere/ux.uxframe-react";

export default function SMModal(props) {
  return (
    <Modal show={props.showModal} onHide={props.handleCancel} centered>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
        <CloseButton onClick={props.handleCancel} />
      </Modal.Header>

      <Modal.Body className="modalScroll">
        <Alert variant="warning">
          <h5 className="uxf-alert-title">{props.msg_line1}</h5>
          {props.msg_line2 && (
            <h5 className="uxf-alert-title">{props.msg_line2}</h5>
          )}
        </Alert>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant={props.information ? "secondary" : "primary"}
          onClick={props.handleCancel}
        >
          {props.cancelText}
        </Button>
        <Button
          variant={props.information ? "primary" : "secondary"}
          onClick={props.handleYes && ((e) => props.handleYes(e, props.id))}
        >
          {props.yesText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
