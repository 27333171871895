export const iconSizeSmall = {
  height: 20,
  width: 20,
};

export const iconSizeBig = {
  height: 32,
  width: 32,
};

export const clearIcon = (iconSize) => ({
  style: {
    ...iconSize,
    fill: "#aaa",
  },
});

export const searchIcon = (iconSize) => ({
  style: iconSize,
});
