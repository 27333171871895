import React from "react";
import { Col, Row } from "@deere/ux.uxframe-react";
import { Accordion } from "../shared/base-accordion/accordion";
import { ReactComponent as Indeterminate } from "../Icons/CheckboxIndeterminate.svg";

export default function RPCOrUnitsViewOnly(props) {
  const rpcOrUnitsFromParentRead = props.rpcOrUnitsFromParentRead;
  const masterRpcOrUnits = props.masterRpcOrUnits;

  return (
    <div className="pt-2">
      <Row>
        <Col>
          <Row>
            {masterRpcOrUnits &&
              masterRpcOrUnits.map((currentRecord, index) => {
                let hasUnits = false;
                let rpcMatch = rpcOrUnitsFromParentRead.rpcs.filter(
                  (record) => record === currentRecord.rpcCode
                );
                currentRecord.units.forEach((unit) => {
                  let unitsMatch = rpcOrUnitsFromParentRead.units.filter(
                    (record) => record === unit.code
                  );
                  //checks for every unit match, if length is>0 it reset hasUnits to true. hasUnits helps to determine status for RPC Level.
                  unitsMatch.length > 0 && (hasUnits = true);
                });

                return (
                  (rpcMatch.length > 0 || hasUnits) && (
                    <Row className="col-md-24">
                      <Col className="col-md-30">
                        <Accordion
                          color={"WHITE"}
                          data-testid={`standard-accordion-${"SMALL"}-${"WHITE"}`}
                          header={
                            hasUnits ? (
                              <span style={{ fontSize: "14px" }}>
                                <Indeterminate /> {currentRecord.rpcName}
                              </span>
                            ) : (
                              <span className="tickMark">
                                {currentRecord.rpcName}
                              </span>
                            )
                          }
                          size={"SMALL"}
                          isOpen={hasUnits ? true : false}
                        >
                          <div className="containerViewOnly">
                            {currentRecord.units.map((unit) => {
                              let unitsMatch = rpcOrUnitsFromParentRead.units.filter(
                                (record) => record === unit.code
                              );
                              return (
                                <>
                                  <Row>
                                    <span
                                      className={
                                        rpcMatch.length > 0
                                          ? "fontStyle"
                                          : unitsMatch.length > 0
                                          ? "tickMark"
                                          : "text-black-50 ml-4"
                                      }
                                    >
                                      {unit.name + " - " + unit.code}
                                    </span>
                                  </Row>
                                </>
                              );
                            })}
                          </div>
                        </Accordion>
                      </Col>
                    </Row>
                  )
                );
              })}
          </Row>
        </Col>
      </Row>
    </div>
  );
}
