import { ListItem } from "@deere/ux.uxframe-react";
import { Link } from "react-router-dom";
import {FORM_TYPE_ID_NOT_IN_STRATEGY, SBF} from "../shared/AppConstants";

export default function SDForm(props) {
  let { sdform, unitName, updatedByUserName } = props;
  let dateFormat = require("dateformat");
  let formatString = "dd-mmm-yyyy";

  return (
    <ListItem className="list-group-item mb-1">
      <div>
        <span className="d-flex w-100 justify-content-between">
          <Link
            to={{
              pathname: "/viewSDForm/" + sdform.formId,
            }}
          >
            <strong>{SBF} #{"  "} </strong> {sdform.formId}
          </Link>

          <span>
            {" "}
            {sdform.formType === FORM_TYPE_ID_NOT_IN_STRATEGY && (
              <strong>Not In Strategy </strong>
            )}
          </span>
        </span>
      </div>

      <div>
        <span className="d-flex w-100 justify-content-between">
          <span>
            <span>
              <strong>Unit{"     "} </strong>{" "}
            </span>
            <span> {unitName}</span>
          </span>
          <span>
            <strong>Last Updated{"  "} </strong>
            {dateFormat(new Date(sdform.updatedOn), formatString)}
          </span>
        </span>
      </div>
      <div>
        <span className="d-flex w-100 justify-content-between">
          <span>
            <strong>PO / Scheduling Agreement{"  "} </strong>
            {sdform.nonStrategyFormPONumber}
          </span>

          <span>
            <strong>Last Updated By{"  "} </strong>
            {updatedByUserName}
          </span>
        </span>
      </div>
    </ListItem>
  );
}
