import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Form,
  Col,
  Row,
  Card,
  ToggleButtonGroup,
  ToggleButton,
  HelperText,
  Button,
} from "@deere/ux.uxframe-react";
import {
  putOrPostStrategy,
  callOwnerInfoAPI,
  callSdfAPI,
} from "../shared/SDFHelper";
import usePrompt from "../shared/CustomPrompt";
import NoAccessEmployee from "./NoAccessEmployee";
import { useAPIProgress } from "../shared/APIProgress";
import { fetchJsonWithAuth } from "../shared/fetch-json.util";
import { useStatusMessages } from "../shared/StatusMessages";
import { useUserInfo } from "../shared/UserInfo";
import MessageBanner from "../shared/MessageBanner";
import GetProspectingAndReasonCodes from "./GetProspectingAndReasonCodes";
import GetSDIMasterList from "./GetSDIMasterList";
import GetUnits from "./GetUnits";
import SDSConsidered from "./SDSConsidered";
import UnitDropDownSearch from "./UnitDropDownSearch";
import { jdsnReactSelect } from "../shared/jdsnReactSelect";
import { jdsnReactSelectError } from "../shared/jdsnReactSelectError";

import SMModal from "../shared/SMModal";
import {
  FORM_TYPE_IN_STRATEGY,
  FORM_TYPE_NOT_IN_STRATEGY,
  FORM_TYPE_ID_NOT_IN_STRATEGY, SMALL_BUSINESS_FORM, MESSAGES, LABELS
} from "../shared/AppConstants";
import { isFormNotGoodForSave } from "../shared/UtilFunctions";

export default function NonStrategySDForm() {
  let dateFormat = require("dateformat");
  let formatString = "dd-mmm-yyyy";
  const info = useUserInfo();
  const tokenAvailable = info && info.token;
  const loggedInUser = info && info.userId;
  const { sdfId } = useParams();
  const [hasAccess, setHasAccess] = useState(null);

  const [sdfNumber, setSdfNumber] = useState(sdfId ? parseInt(sdfId) : 0);
  const [prospectingTool, setProspectingTool] = useState(null);
  const [reasonCodes, setReasonCodes] = useState(null);
  const [sdiMasterList, setSdiMasterList] = useState(null);
  const [unitMap, setUnitMap] = useState(null);
  const [isFormDirty] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [sdfUser, setSdfUser] = useState(null);
  const [sdfInfo, setSdfInfo] = useState(null);
  const [suppliersMap, setSuppliersMap] = useState(null);

  const [formType] = useState(FORM_TYPE_NOT_IN_STRATEGY);

  const [headerText, setHeaderText] = useState(
    sdfNumber
      ? `${SMALL_BUSINESS_FORM} #` + sdfNumber
      : `${LABELS.CREATE_SMALL_BUSINESS_FORM}`
  );
  const [saveOrSubmitButtonText, setSaveOrSubmitButtonText] = useState(
    sdfNumber ? "Save" : "Submit"
  );
  const { apiInProgressCount, incrementAPIInProgress, decrementAPIInProgress } =
    useAPIProgress();
  const history = useHistory();

  const {
    register,
    unregister,
    getValues,
    reset,
    control,
    handleSubmit,
    setError,
    errors,
    setValue,
    formState,
    clearErrors,
    watch,
  } = useForm({
    mode: "all",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });
  const watchFields = watch();
  const { isDirty } = formState;

  const {
    statusMessages,
    clearAllMessages,
    setStatusSuccessMsg,
    setStatusErrorMsg,
  } = useStatusMessages();

  const commonProps = {
    tokenAvailable: tokenAvailable,
    setStatusErrorMsg: setStatusErrorMsg,
    register: register,
    unregister: unregister,
    getValues: getValues,
    incrementAPIInProgress: incrementAPIInProgress,
    decrementAPIInProgress: decrementAPIInProgress,
    control: control,
    setValue: setValue,
    errors: errors,
    reset: reset,
    clearErrors: clearErrors,
    watch: watch,
    setError: setError,
    fields: fields,
    append: append,
    remove: remove,
  };

  const handleChangeInput = (event) => {
    const { value, minLength } = event.target;

    if (value.length < minLength || !/^\d+$/.test(value))
      setError("POorSANumber", {
        message: "6 to 20 digits required.",
        type: "manual",
      });
    else clearErrors(["POorSANumber"]);
  };

  function cancelStrategy() {
    if (isDirty || isFormDirty) {
      setShowModal(true);
    } else windowClose();
  }
  function windowClose() {
    if (window.opener) window.close();
    else history.push("/");
  }
  function save(data) {
    clearAllMessages();

    let putOrPost = putOrPostStrategy(data, sdfNumber);

    incrementAPIInProgress();
    fetchJsonWithAuth(putOrPost.url, {
      method: putOrPost.method,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(putOrPost.body),
    })
      .then((response) => {
        const result = response.json;
        const { formId } = result;

        if (result.hasOwnProperty("400")) setStatusErrorMsg(result["400"]);
        else if (formId) {
          let message1;

          if (putOrPost.method === "POST") {
            message1 = "SDF created successfully";
            setSaveOrSubmitButtonText("Save");
            setHeaderText(`${SMALL_BUSINESS_FORM} #` + result.formId);
            setSdfInfo(result);
            setSdfNumber(result.formId);
            callOwnerInfoAPI(
              result.updatedById,
              setSdfUser,
              incrementAPIInProgress,
              decrementAPIInProgress,
              setStatusErrorMsg
            );
          } else {
            message1 = "SDF Updated successfully";
            callSdfAPI(
              sdfNumber,
              setSdfUser,
              setSdfInfo,
              setSuppliersMap,
              incrementAPIInProgress,
              decrementAPIInProgress,
              setStatusErrorMsg
            );
          }
          setStatusSuccessMsg(message1);
        }
      })
      .catch((error) =>
        setStatusErrorMsg(MESSAGES.SBF_SAVE_ERROR, error)
      )
      .finally((info) => decrementAPIInProgress());
  }

  function awayFunc(e) {
    e.returnValue = isDirty ? "" : null;
  }

  usePrompt(!showModal && isDirty);

  useEffect(() => {
    if (isDirty) window.addEventListener("beforeunload", awayFunc);
    else window.removeEventListener("beforeunload", awayFunc);
    return () => window.removeEventListener("beforeunload", awayFunc);
  }, [isDirty]);

  useEffect(() => {
    let data = getValues();
    let isDisabled = true;

    if (
      isDirty &&
      data["unit"] &&
      data["POorSANumber"] &&
      data["POorSANumber"].length >= 6 &&
      /^\d+$/.test(data["POorSANumber"])
    )
      isDisabled = isFormNotGoodForSave(data);

    setDisabled(isDisabled);
  }, [watchFields]);

  useEffect(() => {
    if (tokenAvailable && sdfNumber > 0) {
      callSdfAPI(
        sdfNumber,
        setSdfUser,
        setSdfInfo,
        setSuppliersMap,
        incrementAPIInProgress,
        decrementAPIInProgress,
        setStatusErrorMsg
      );
    }
  }, [sdfNumber, tokenAvailable]);

  useEffect(() => {
    if (info) {
      let flag = info.hasNonStrategySDFAccess;
      if (sdfInfo)
        flag = flag && sdfInfo.formType === FORM_TYPE_ID_NOT_IN_STRATEGY;

      setHasAccess(flag ? "Yes" : "No");
    }
  }, [sdfInfo, info]);

  return !info || !hasAccess ? (
    <></>
  ) : hasAccess === "No" ? (
    <NoAccessEmployee userId={loggedInUser} />
  ) : (
    <main id="content" className="container-fluid pb-5 bg-gray">
      <Form onSubmit={handleSubmit(save)}>
        <div className="container-fluid pb-2 bg-gray position-sticky fixed-top">
          <Row className="mt-3">
            <Col>
              <h2 className="mt-2 float-left">{headerText}</h2>
            </Col>
            <Col>
              <div className="mt-2 float-right">
                <Button variant="secondary" onClick={cancelStrategy}>
                  Cancel
                </Button>
                <Button
                  title={
                    disabled &&
                    (isDirty || sdfNumber === 0
                      ? "Please complete all required fields."
                      : "Please update vetted suppliers section or add comments to enable Save.")
                  }
                  variant="primary"
                  type="submit"
                  disabled={disabled}
                >
                  {saveOrSubmitButtonText}
                </Button>
              </div>
            </Col>
          </Row>
          {sdfNumber > 0 && (
            <h6>
              Last updated on{" "}
              {sdfInfo && dateFormat(new Date(sdfInfo.updatedOn), formatString)}{" "}
              by {sdfUser ? sdfUser : sdfInfo && sdfInfo.updatedById}
            </h6>
          )}
          <br />
          <MessageBanner
            statusMessages={statusMessages}
            apiInProgressCount={apiInProgressCount}
          />
        </div>
        <GetProspectingAndReasonCodes
          commonProps={commonProps}
          setReasonCodes={setReasonCodes}
          setProspectingTool={setProspectingTool}
        />
        <GetSDIMasterList
          commonProps={commonProps}
          setSdiMasterList={setSdiMasterList}
        />
        <GetUnits commonProps={commonProps} setUnitMap={setUnitMap} />

        <Row className="mt-3">
          <Col>
            <Card>
              <Card.Body>
                <Form.Row>
                  <Form.Group as={Col} xs={15} md={10}>
                    <ToggleButtonGroup
                      role="toolbar"
                      includeLabel
                      className="mt-3"
                      label="Is this for a Strategy or a single purchase (not in
                      Strategy)?"
                      name="radioButtonGroup"
                      type="radio"
                      value={formType === FORM_TYPE_IN_STRATEGY ? 1 : 2}
                      ariaLabel="Is this for a Strategy or a single purchase (not in
                        Strategy)?"
                    >
                      <ToggleButton
                        value={1}
                        disabled={formType !== FORM_TYPE_IN_STRATEGY}
                      >
                        In Strategy
                      </ToggleButton>
                      <ToggleButton
                        value={2}
                        disabled={formType !== FORM_TYPE_NOT_IN_STRATEGY}
                      >
                        Not In Strategy
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={8}>
                    <Form.Label className="uxf-label">
                      Purchase Order or Scheduling Agreement Number{" "}
                      <span
                        aria-label="required field"
                        className="uxf-is-required"
                      >
                        *
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      ref={(e) => {
                        register(e, {
                          required: "This is a required field!",
                        });
                      }}
                      name="POorSANumber"
                      maxLength="20"
                      minLength="6"
                      onChange={handleChangeInput}
                      onBlur={handleChangeInput}
                      className={errors.POorSANumber && "error"}
                    />
                    {errors && errors.POorSANumber && (
                      <div>
                        <HelperText
                          helperText=""
                          helperTextId=""
                          className="uxf-is-required"
                        >
                          {errors.POorSANumber.message}
                        </HelperText>
                      </div>
                    )}
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={8}>
                    <Form.Label className="uxf-label">
                      Unit{" "}
                      <span
                        aria-label="required field"
                        className="uxf-is-required"
                      >
                        *
                      </span>
                    </Form.Label>
                    {unitMap && (
                      <>
                        <UnitDropDownSearch
                          key={sdfInfo && sdfInfo.nonStrategyFormUnitCode}
                          readOnly={false}
                          name="unit"
                          setStatusErrorMsg={setStatusErrorMsg}
                          styles={
                            errors.unit ? jdsnReactSelectError : jdsnReactSelect
                          }
                          defaultValue={
                            sdfInfo && sdfInfo.nonStrategyFormUnitCode
                          }
                          rules={{ required: true }}
                          commonProps={commonProps}
                          unitMap={unitMap}
                        />
                        {errors.unit && (
                          <div>
                            <HelperText
                              helperText=""
                              helperTextId=""
                              className="uxf-is-required"
                            >
                              This is a required field!
                            </HelperText>
                          </div>
                        )}
                      </>
                    )}
                  </Form.Group>
                </Form.Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md="24">
            <SDSConsidered
              commonProps={commonProps}
              reasonCodes={reasonCodes}
              suppliersMap={suppliersMap}
              prospectingTool={prospectingTool}
              isDirty={isFormDirty}
              sdfInfo={sdfInfo}
              initialSdsConsidered={
                sdfInfo ? (sdfInfo.sdsConsidered ? "Yes" : "No") : ""
              }
              sdiMasterList={sdiMasterList}
            />
          </Col>
        </Row>
      </Form>
      <SMModal
        showModal={showModal}
        handleCancel={() => setShowModal(false)}
        handleYes={() => windowClose()}
        title="Changes Will Be Lost"
        msg_line1="Do you want to save your changes before leaving this screen?"
        msg_line2="If you don't save, your changes will be lost."
        cancelText="Cancel"
        yesText="Continue without Saving"
      />
    </main>
  );
}
