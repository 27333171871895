import { useEffect } from "react";
import React from "react";
import { fetchJsonWithAuth } from "../shared/fetch-json.util";

export default function GetProspectingAndReasonCodes(props) {
  const {
    tokenAvailable,
    setStatusErrorMsg,
    incrementAPIInProgress,
    decrementAPIInProgress,
  } = props.commonProps;
  const { setProspectingTool, setReasonCodes } = props;

  const fillProspectingTools = () => {
    let api = process.env.REACT_APP_PROSPECTING_TOOL_API_URL;

    incrementAPIInProgress();
    fetchJsonWithAuth(api)
      .then((response) => {
        setProspectingTool(response.json);
      })
      .catch((error) =>
        setStatusErrorMsg(
          "Couldn't retrieve Prospecting tool codes. Please try again.",
          error
        )
      )
      .finally((info) => decrementAPIInProgress());
  };

  function fillReasonCOdes() {
    let api = process.env.REACT_APP_REASON_CODE_API_URL;

    incrementAPIInProgress();
    fetchJsonWithAuth(api)
      .then((response) => {
        const result = response.json;

        setReasonCodes(result);
      })
      .catch((error) =>
        setStatusErrorMsg(
          "Couldn't retrieve the Reason codes. Please try again.",
          error
        )
      )
      .finally((info) => decrementAPIInProgress());
  }

  useEffect(() => {
    if (tokenAvailable) {
      fillProspectingTools();
      fillReasonCOdes();
    }
  }, [tokenAvailable]);

  return <></>;
}
