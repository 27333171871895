import PropTypes from "prop-types";

const STANDARD_INPUT_DELAY_IN_MS = 300;
const noOp = () => null;

const basePropTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  debounceTimeout: PropTypes.number,
  disabled: PropTypes.bool,
  inputRef: PropTypes.object,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onFocus: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export const baseDefaultProps = {
  className: "",
  debounceTimeout: STANDARD_INPUT_DELAY_IN_MS,
  disabled: false,
  onBlur: noOp,
  onChange: noOp,
  onClear: noOp,
  onFocus: noOp,
  onSearch: noOp,
};

export const baseInputFilterPropTypes = {
  ...basePropTypes,
  controls: PropTypes.node,
};

export const inputFilterPropTypes = {
  ...basePropTypes,
};

export const searchInputFilterPropTypes = {
  ...inputFilterPropTypes,
  constraintsMenu: PropTypes.array,
  onConstraintChange: PropTypes.func,
  selectedConstraint: PropTypes.string,
};
