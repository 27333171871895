import _ from "lodash";
import { useState, useEffect } from "react";
import { useUserLogin, getSessionInfo } from "./UserLogin";

import {
  setAuthorizationToken,
  setLoggedInUserId,
} from "../shared/fetch-json.util";

export function useUserInfo() {
  // need to do this if session is cleared out
  const [userInfo, logout, userObj] = useUserLogin();
  const [info, setInfo] = useState(getSessionInfo());

  useEffect(() => {
    if (!info || (userObj && !_.isEqual(info.roles, userObj.roles)))
      setInfo(userObj);
  }, [userInfo, userObj]);
  if (info) {
    setAuthorizationToken(info.token);
    setLoggedInUserId(info.userId);
  }
  return info;
}
