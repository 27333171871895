import React from "react";

import { ListItem, Footer, List, FooterLink } from "@deere/ux.uxframe-react";
import { getContactUsLink } from "../shared/UtilFunctions";
import { useUserInfo } from "../shared/UserInfo";

export default function SMFooter() {
  const info = useUserInfo();
  const loggedInUser = info && info.userId;

  return (
    <Footer>
      <List className="nav">
        <ListItem>
          <FooterLink href="https://jdsn.deere.com"> Home </FooterLink>
        </ListItem>
        <ListItem>
          <FooterLink
            href="http://www.deere.com/suppliercode"
            target="_blank"
            rel="noopener noreferrer"
          >
            Supplier Code of Conduct
          </FooterLink>
        </ListItem>
        <ListItem>
          <FooterLink
            href="https://www.deere.com/en/privacy-and-data/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy &amp; Data
          </FooterLink>
        </ListItem>
        <ListItem>
          <FooterLink
            href="https://www.deere.com/en/privacy-and-data/terms/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </FooterLink>
        </ListItem>
        <ListItem>
          <FooterLink
            href={getContactUsLink(loggedInUser)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact Us
          </FooterLink>
        </ListItem>
      </List>
    </Footer>
  );
}
