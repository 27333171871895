import { Col, Form, Card } from "@deere/ux.uxframe-react";
import React from "react";
import { PROSPECTIVE } from "../shared/AppConstants";

export default function VettedSupplierViewOnly(props) {
  const { vettedSupplier, suppliersMap, prospectingTool, reasonCodes } = props;

  function getSupplier(supplierInfo) {
    if (suppliersMap) {
      let supplierDetails = suppliersMap && suppliersMap.get(supplierInfo);
      return supplierDetails.label;
    }
  }

  function getSupplierSdi(supplierInfo) {
    if (suppliersMap) {
      let supplierDetails = suppliersMap && suppliersMap.get(supplierInfo);
      return supplierDetails.sdis.join(", ");
    }
  }

  return (
    <>
      <Card>
        <Card.Body>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="uxf-label">
                Supplier Type{" "}
                <span aria-label="required field" className="uxf-is-required">
                  *
                </span>
              </Form.Label>
              <br />
              <span>
                {" "}
                {vettedSupplier.supplierType === PROSPECTIVE
                  ? "Non Current or Prospective"
                  : vettedSupplier.supplierType}{" "}
                Supplier{" "}
              </span>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="uxf-label">
                {vettedSupplier.supplierType === PROSPECTIVE
                  ? "Supplier Name"
                  : "Supplier"}{" "}
                <span aria-label="required field" className="uxf-is-required">
                  *
                </span>
              </Form.Label>
              <br />
              {vettedSupplier.supplierType === PROSPECTIVE ? (
                <span> {vettedSupplier.nameOrNumber} </span>
              ) : (
                <span>{getSupplier(vettedSupplier.nameOrNumber)}</span>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="uxf-label">
                Supplier Diversity Indicator (SDI) Type{" "}
                <span aria-label="required field" className="uxf-is-required">
                  *
                </span>
              </Form.Label>
              <br />
              {vettedSupplier.supplierType === PROSPECTIVE
                ? vettedSupplier.vettedProspectiveSupplierSDIS
                    .map((sdi) => {
                      return sdi.sdi;
                    })
                    .join(", ")
                : getSupplierSdi(vettedSupplier.nameOrNumber)}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            {prospectingTool && (
              <Form.Group as={Col} xs={10} md={8}>
                <Form.Label className="uxf-label">
                  Prospecting Tool{" "}
                  <span aria-label="required field" className="uxf-is-required">
                    *
                  </span>
                </Form.Label>
                <br />
                {prospectingTool.map((option) => {
                  if (option.code.toString() === vettedSupplier.prospectingTool)
                    return option.name;
                })}
              </Form.Group>
            )}
            <Form.Group as={Col}>
              <Form.Label className="uxf-label">
                Reason Code{" "}
                <span aria-label="required field" className="uxf-is-required">
                  *
                </span>
              </Form.Label>
              <br />
              {reasonCodes &&
                reasonCodes.map((option) => {
                  if (option.code.toString() === vettedSupplier.reasonCode)
                    return option.name;
                })}
              <br />
              {vettedSupplier.comments && (
                <span>{vettedSupplier.comments}</span>
              )}
            </Form.Group>
          </Form.Row>
        </Card.Body>
      </Card>
      <br />
    </>
  );
}
