import {
  STRATEGY_TYPE_DIRECT,
  STRATEGY_TYPE_INDIRECT,
  INDIRECT_STRATEGY_MANAGEMENT,
  DIRECT_STRATEGY_MANAGEMENT,
} from "../shared/AppConstants";

import {
  Form,
  Col,
  ToggleButtonGroup,
  ToggleButton,
} from "@deere/ux.uxframe-react";

export default function StrategyType(props) {
  const { applicationName, strategyInfo } = props;

  function isDisabled(type) {
    return applicationName
      ? (applicationName === INDIRECT_STRATEGY_MANAGEMENT &&
          type === STRATEGY_TYPE_DIRECT) ||
          (applicationName === DIRECT_STRATEGY_MANAGEMENT &&
            type === STRATEGY_TYPE_INDIRECT)
      : strategyInfo && strategyInfo.directIndirect !== type;
  }

  return (
    <Form.Group as={Col} xs={12} md={8}>
      <ToggleButtonGroup
        role="toolbar"
        includeLabel
        className="mt-3"
        label="Is this for Direct or Indirect Materials?"
        name="radioButtonGroup0"
        type="radio"
        value={
          applicationName === INDIRECT_STRATEGY_MANAGEMENT
            ? 4
            : applicationName === DIRECT_STRATEGY_MANAGEMENT
            ? 3
            : strategyInfo &&
              strategyInfo.directIndirect === STRATEGY_TYPE_DIRECT
            ? 3
            : 4
        }
        ariaLabel="Is this for Direct or Indirect Materials?"
      >
        <ToggleButton value={3} disabled={isDisabled(STRATEGY_TYPE_DIRECT)}>
          Direct
        </ToggleButton>
        <ToggleButton value={4} disabled={isDisabled(STRATEGY_TYPE_INDIRECT)}>
          Indirect
        </ToggleButton>
      </ToggleButtonGroup>
    </Form.Group>
  );
}
