import React, { useState, useEffect } from "react";
import { Facet } from "../shared/Facet";
import { Checkbox } from "@deere/ux.uxframe-react/lib/components/Checkbox";
import { ListFilter } from "../shared/ListFilter/index";

export default function UnitsFilter(props) {
  const filterId = "Unit_filter";
  const { unitMap, setParentFilter } = props;
  const info = props.info;
  const { setSelectedUnits, selectedValues } = props;
  const [selectedLabels, setSelectedLabels] = useState(null);

  const [unitList, setUnitList] = useState(null);

  function filterHandler(index, text) {
    const targetItem = unitList[index];
    const filterText = text ? text.toLowerCase() : "";

    if (filterText === "") return true;

    let array = filterText.replace(",", " ").split(" ");
    let isPresent = true;

    array.forEach((item) => {
      if (item !== "")
        isPresent =
          isPresent && targetItem.name.toLowerCase().indexOf(item) > -1;
    });
    return isPresent;
  }
  useEffect(() => {
    if (unitMap) {
      const data = Array.from(unitMap).map((item) => {
        return {
          id: item[0],
          name: item[1] + " - " + item[0],
          checked: false,
        };
      });
      setUnitList(data);
    }
  }, [unitMap]);

  function processChange(setONParent) {
    let checkedUnitParent = "";
    let checkedUnit = [];
    let checkedUnitObjList = [];

    unitList &&
      unitList.forEach((element) => {
        if (element.checked) {
          checkedUnit.push({ parent: element.name });
          checkedUnitObjList.push(element);
          checkedUnitParent +=
            checkedUnitParent === "" ? element.id : "," + element.id;
        }
      });
    setSelectedLabels(checkedUnit);
    setSelectedUnits(checkedUnitParent);
    setONParent &&
      setParentFilter &&
      setParentFilter({ unitFilter: checkedUnitObjList });
  }

  const toggleSelectedFilter = (unit) => {
    unitList.forEach((element) => {
      if (unit.name === element.name) element.checked = !element.checked;
    });
    processChange(true);
  };

  const clearSelection = () => {
    unitList.forEach((record) => (record.checked = false));
    processChange(true);
  };

  useEffect(() => {
    if (unitList) {
      let obj;

      if (selectedValues) {
        selectedValues.forEach((element) => {
          obj = unitList.find((item) => item.id === element);
          if (obj) obj.checked = true;
        });
      } else unitList.forEach((obj) => (obj.checked = false));
      processChange(false);
    }
  }, [selectedValues, unitList, info]);

  return (
    <Facet>
      <Facet.Item
        id={filterId}
        title="Unit"
        selectedItems={selectedLabels}
        clearSelection={clearSelection}
      >
        {unitList && (
          <ListFilter filter={filterHandler} placeholder="Search" inputLabel="">
            <ul>
              {unitList.map((unit) => {
                return (
                  <li key={filterId + unit.id}>
                    <Checkbox
                      id={filterId + unit.id}
                      label={unit.name}
                      checked={unit.checked}
                      onChange={() => toggleSelectedFilter(unit)}
                    ></Checkbox>
                  </li>
                );
              })}
            </ul>
          </ListFilter>
        )}
      </Facet.Item>
    </Facet>
  );
}
