import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import "@deere/ux.uxframe-core/dist/css/uxframe-2019.3.0.min.css";
import "./App.css";
import AppRouter from "./AppRouter";

export default function App() {
 
  return (    
        <Router>
          <AppRouter />
        </Router>      
  );
}
