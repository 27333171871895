import { fetchJsonWithAuth } from "./fetch-json.util";

export function recordSAMUserActivity(userId, appId) {
  let url =
    process.env.REACT_APP_SAM_API_URL +
    "/applications/" +
    appId +
    "/" +
    userId +
    "/N";

  fetchJsonWithAuth(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify({
      type: "",
      url: "",
      itemHeader: "",
    }),
  }).catch((error) => console.log("SAM Usage Stats API Failure: " + error));
}
