import { Form, Col, Card } from "@deere/ux.uxframe-react";
import StrategyOrSinglePO from "./StrategyOrSinglePO";
import StrategyType from "./StrategyType";
import DMSMMGC from "./DMSMMGC";
import DMSMScope from "./DMSMScope";
import UnitorUnitGroupsViewOnly from "../shared/UnitorUnitGroupsViewOnly";
import {
  DIRECT_STRATEGY_MANAGEMENT,
  SCOPE_LEVEL_UNITORUNITGROUP,
} from "../shared/AppConstants";
import GetMasterScopes from "./GetMasterScopes";
import { useState } from "react";

export default function GSSStrategy(props) {
  const {
    formType,
    strategyInfo,
    applicationName,
    strategyOwnerInfo,
    commonProps,
  } = props;
  const [masterScopeJson, setMasterScopeJson] = useState(null);
  const [masterScopeMap, setMasterScopeMap] = useState(null);

  const childProps = {
    ...commonProps,
    setMasterScopeJson: setMasterScopeJson,
    setMasterScopeMap: setMasterScopeMap,
  };

  return (
    <Card>
      <Card.Body>
        <Form.Row>
          <StrategyOrSinglePO formType={formType} />
          <StrategyType
            applicationName={applicationName}
            strategyInfo={strategyInfo}
          />
          {applicationName === DIRECT_STRATEGY_MANAGEMENT && (
            <GetMasterScopes commonProps={childProps} />
          )}
          <Form.Group as={Col} xs={9} md={6}>
            <Form.Label className="uxf-label">Strategy Owner</Form.Label>
            <h5>
              {strategyOwnerInfo
                ? strategyOwnerInfo
                : strategyInfo && strategyInfo.strategyOwnerId}
            </h5>
          </Form.Group>
        </Form.Row>
        <br />
        <Form.Row>
          <DMSMMGC
            formType={formType}
            strategyInfo={strategyInfo && strategyInfo}
            applicationName={applicationName}
          />
          {applicationName === DIRECT_STRATEGY_MANAGEMENT ? (
            strategyInfo &&
            strategyInfo.scope && (
              <div
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  maxHeight: "200px",
                  width: "450px",
                }}
              >
                <Form.Label className="uxf-label">Scope </Form.Label> <br></br>
                <b>{strategyInfo.scope.level}</b>
                {strategyInfo.scope.level === SCOPE_LEVEL_UNITORUNITGROUP &&
                  masterScopeJson && (
                    <UnitorUnitGroupsViewOnly
                      formType={formType}
                      unitorUnitGroupsFromParentRead={
                        strategyInfo && strategyInfo.scope
                      }
                      masterScopeJson={masterScopeJson}
                      commonProps={commonProps}
                    />
                  )}
              </div>
            )
          ) : (
            <DMSMScope formType={formType} strategyInfo={strategyInfo} />
          )}
        </Form.Row>
      </Card.Body>
    </Card>
  );
}
